.region-select {
    position: relative;
    display: block;
    margin: auto 0;
    transition: opacity 0.3s;

    select {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        pointer-events: none;
    }

    .region-selected {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        padding: 15px 25px 15px 15px;
        background-color: #ffffff;
        cursor: pointer;

        @include is-screen-max($breakpoint-md) {
            padding: 10px 25px 10px 10px;
        }

        @include is-screen-max($breakpoint-sm) {
            padding-right: 10px;
            padding-left: 5px;
        }

        &__label {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-100%, -50%);
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            white-space: nowrap;
            pointer-events: none;

            @include is-screen-max($breakpoint-md) {
                font-size: 12px;
                line-height: 16px;
            }

            @include is-screen-max($breakpoint-sm) {
                display: none;
            }
        }

        &__icon {
            flex-shrink: 0;
            width: 20px;
            transform: translateY(-3px);

            @include is-screen-max($breakpoint-lg) {
                display: none;
            }
        }

        &__text {
            max-width: 100px;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: opacity 0.3s;

            @include is-screen-max($breakpoint-md) {
                max-width: 80px;
                font-size: 12px;
                line-height: 16px;
            }
        }

        &__arrow {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 9px;
            transition: transform 0.3s;

            @include is-screen-max($breakpoint-md) {
                right: 4px;
                width: 12px;
            }

            @include is-screen-max($breakpoint-sm) {
                right: 0;
                width: 8px;
            }
        }

        &:hover {
            .region-selected {
                &___text {
                    opacity: 0.6;
                }
            }
        }
    }

    .region-cloud {
        position: absolute;
        top: 100%;
        left: 0;
        display: flex;
        align-items: flex-start;
        width: 480px;
        opacity: 0;
        pointer-events: none;
        z-index: 120;
        transition: opacity 0.3s;

        @include is-screen-max($breakpoint-lg) {
            width: 400px;
        }

        @include is-screen-max($breakpoint-md) {
            left: initial;
            right: -100px;
            width: 300px;
        }

        @include is-screen-max($breakpoint-sm) {
            right: -90px;
        }

        &.is-active {
            opacity: 1;
            pointer-events: initial;
        }

        &::before {
            content: "";
            position: absolute;
            left: 16px;
            top: 0;
            transform: translateY(-100%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 9px 9px 9px;
            border-color: transparent transparent #ffffff transparent;

            @include is-screen-max($breakpoint-md) {
                display: none;
            }
        }

        &__content {
            display: flex;
            align-items: center;
            gap: 10px 20px;
            flex-wrap: wrap;
            max-width: 100%;
            padding: 25px 30px;
            border-radius: 4px;
            box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.15);
            background-color: #ffffff;
        }

        .region-selected {
            width: auto;
            padding: 0;
            pointer-events: none;

            &__icon {
                @include is-screen-max($breakpoint-lg) {
                    display: block;
                }
            }

            &__text {
                max-width: 100%;
            }
        }

        &__buttons {
            display: flex;
            align-items: center;
            gap: 10px;

            .btn {
                padding: 0 11px;
                border-width: 3px !important;

                &__name {
                    min-width: 68px;
                    padding: 2px 0 0;
                    transition: color 0.3s;
                }

                &-bordered {
                    border-width: 2px !important;

                    &:hover {
                        border-color: #199629;

                        .btn__name {
                            color: #199629;
                        }
                    }
                }

                &-sm {
                    border-width: 1px;
                    white-space: nowrap;
                }
            }
        }
    }

    &.is-active {
        .region-selected {
            &__arrow {
                transform: translateY(-50%) scaleY(-1);
            }
        }
    }
}
