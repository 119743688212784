/**********************************************************************/
/**********************************************************************/
.page-product {
    margin-bottom: 100px;

    @include is-screen-max($breakpoint-lg) {
        width: 650px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    @include is-screen-max($breakpoint-md) {
        margin-bottom: 60px;
    }

    &__breadcrumbs {
        margin: 50px 0 20px;

        @include is-screen-max($breakpoint-md) {
            margin-top: 20px 0;
        }
    }

    &__back {
        position: relative;
        display: flex;
        margin-bottom: 30px;
        padding-left: 25px;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.6;
        }

        img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-60%);
            width: 22px;
        }

        span {
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #787878;
        }
    }

    &__mobtitle {
        display: none;
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 36px;
        line-height: 130%;
        color: #003201;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-lg) {
            display: block;
        }

        @include is-screen-max($breakpoint-md) {
            margin-bottom: 20px;
            font-size: 28px;
        }
    }

    &__body {
        margin-bottom: 70px;

        @include is-screen-max($breakpoint-lg) {
            flex-direction: column;
        }
    }

    &__slider-col {
        @include is-screen-max($breakpoint-lg) {
            width: 100% !important;
        }
    }

    &__contentcol {
        width: 500px !important;
        max-width: calc(50% - 30px);
        margin-left: auto;

        @include is-screen-max($breakpoint-lg) {
            width: 100% !important;
            max-width: 100%;
        }
    }

    &__titlearea {
        margin-bottom: 15px;
    }

    &__title {
        margin-bottom: 5px;
        font-weight: 300;
        font-size: 36px;
        line-height: 130%;
        color: #003201;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-lg) {
            font-weight: 400;
        }

        @include is-screen-max($breakpoint-md) {
            display: none;
        }
    }

    &__code {
        font-size: 16px;
        line-height: 25px;
        color: #000000;
        text-transform: uppercase;
    }

    &__info {
        display: flex;
        align-items: flex-end;
        gap: 10px 50px;
        margin-bottom: 30px;

        @include is-screen-max($breakpoint-md) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__subtitle {
        font-size: 16px;
        line-height: 25px;
        color: #686b6c;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-md) {
            font-size: 16px;
            line-height: 25px;
        }
    }

    &__price {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        max-width: 140px;

        @include is-screen-max($breakpoint-md) {
            flex-direction: row;
            max-width: 100%;
        }

        p {
            margin: 0 auto;
            font-size: 24px;
            line-height: 110%;
            color: #787878;
            text-align: center;

            @include is-screen-max($breakpoint-md) {
                margin: 0;
            }

            span {
                font-size: 20px;
            }

            &.old {
                position: relative;
                margin-top: 5px;

                @include is-screen-max($breakpoint-md) {
                    margin: auto 0 auto 20px;
                    font-size: 20px;
                }

                &:before {
                    content: "";
                    position: absolute;
                    left: -10px;
                    right: -10px;
                    top: 50%;
                    transform: translateY(-3px) rotate(-3deg);
                    height: 2px;
                    background-color: #e8988d;
                }
            }
        }
    }

    .product-descr {
        width: 100%;

        tbody {
            display: flex;
            flex-direction: column;
            gap: 15px 20px;
            width: 100%;
            border-spacing: 0;

            tr {
                display: flex;
                width: 100%;
                gap: 15px;

                @include is-screen-max($breakpoint-sm) {
                    flex-direction: column;
                }

                td {
                    display: flex;
                    flex-wrap: wrap;
                    width: 50%;
                    gap: 5px 15px;

                    @include is-screen-max($breakpoint-sm) {
                        justify-content: space-between;
                        width: 100%;
                    }

                    & > p {
                        font-size: 18px;
                        line-height: 110%;
                        color: #363636;

                        @include is-screen-max($breakpoint-md) {
                            font-size: 16px;
                        }

                        &:last-child {
                            color: #686b6c;
                        }
                    }

                    .accordion {
                        flex-direction: column;
                        gap: 0;

                        &__head {
                            padding: 0 15px 0 0;
                            border-bottom: none;

                            p {
                                font-size: 18px;
                                line-height: 110%;
                                color: #363636;
                                text-transform: initial;

                                @include is-screen-max($breakpoint-md) {
                                    font-size: 16px;
                                }
                            }

                            &:before {
                                width: 10px;
                            }

                            &:after {
                                right: 4px;
                                height: 10px;
                            }
                        }

                        &__body {
                            margin-top: 5px;
                            padding-top: 5px;
                            margin-bottom: 0;
                            border-top: 1px solid rgba(0, 0, 0, 0.2);
                        }
                    }
                }
            }
        }
    }

    &__scroller {
        margin: 30px 0 40px auto;
        font-size: 18px;
        line-height: 110%;
        color: #363636;
        text-decoration-line: underline;
        text-underline-offset: 5px;
        text-decoration-thickness: 1px;
        transition: opacity 0.3s;

        @include is-screen-max($breakpoint-lg) {
            margin-left: 0;
        }

        @include is-screen-max($breakpoint-md) {
            margin-top: 20px;
            font-size: 16px;
        }

        &:hover {
            @include is-screen-min($breakpoint-md) {
                opacity: 0.6;
            }
        }
    }

    .product-nav {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        @include is-screen-max($breakpoint-sm) {
            flex-direction: column;
        }

        &__col {
            display: flex;
            flex-direction: column;

            &:last-child {
                align-items: flex-end;
            }
        }

        &__buttons {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 20px;

            .quantity {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 126px;
                height: 50px;
                padding: 0 17px;
                margin: 0;
                border-radius: 50px;
                border: 1px solid rgba(47, 47, 47, 0.2);
                background: #ffffff;

                &__btn {
                    position: relative;
                    flex-shrink: 0;
                    width: 13px;
                    height: 13px;
                    transition: opacity 0.3s;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.6;
                    }

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 1px;
                        background-color: #787878;
                    }

                    &::before {
                        width: 13px;
                        height: 1px;
                    }

                    &::after {
                        width: 1px;
                        height: 13px;
                    }

                    &.minus {
                        &::after {
                            display: none;
                        }
                    }
                }

                input {
                    min-width: 0;
                    padding: 0 20px 0 0;
                    border: none;
                    background-color: transparent;
                    font-family: "Muller", sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #868686;
                    text-align: center;
                    outline: none;
                    pointer-events: none;
                    -moz-appearance: textfield;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        margin: 0;
                        -webkit-appearance: none;
                    }
                }

                &::after {
                    content: "уп";
                    position: absolute;
                    top: 50%;
                    right: 40px;
                    transform: translateY(-50%);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 100%;
                    color: #868686;
                    pointer-events: none;
                }
            }
        }

        &__wish {
            position: relative;
            width: 50px;
            height: 50px;
            border: 1px solid rgba(47, 47, 47, 0.2);
            border-radius: 50%;
            cursor: pointer;

            &.is-active {
                img {
                    &:nth-child(1) {
                        opacity: 0;
                    }

                    &:nth-child(2) {
                        opacity: 1;
                    }
                }
            }

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 20px;
                transition: opacity 0.3s;

                &:nth-child(2) {
                    opacity: 0;
                }
            }
        }

        &__package {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 110%;
            color: #448a33;
        }

        &__note {
            font-size: 14px;
            line-height: 110%;
            color: #363636;
        }

        &__total-content {
            display: flex;
            align-items: flex-end;
            gap: 8px;
            margin-top: 10px;
            margin-bottom: 20px;

            @include is-screen-max($breakpoint-md) {
                margin-top: 0;
            }
        }

        &__total {
            flex-shrink: 0;
            font-size: 20px;
            line-height: 110%;
            color: #363636;

            span {
                font-size: 30px;
                line-height: 110%;
            }
        }

        &__price-note {
            flex-shrink: 0;
            padding-bottom: 4px;
            font-size: 16px;
            line-height: 110%;
            color: #448a33;
        }

        &__btn {
            width: 170px;
            max-width: 100%;
            min-height: 50px;
            min-width: initial;
            margin-right: 0;
            border-radius: 30px;

            .btn {
                &__name {
                    font-size: 12px;
                }
            }
        }
    }

    .tabs {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        padding-top: 40px;
        margin-bottom: 40px;

        .tabs-nav {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-bottom: 60px;

            @include is-screen-max($breakpoint-md) {
                gap: 8px;
                margin-bottom: 30px;
            }

            .btn {
                min-height: initial;
                min-width: initial;
                margin-right: initial;
                border-width: 1px;
                border-color: #bfbfbf;
                transition: border-color 0.3s, background-color 0.3s;

                &:hover {
                    @include is-screen-min($breakpoint-md) {
                        border-color: #868686;
                        background-color: #868686;

                        .btn__name {
                            color: #ffffff;
                        }
                    }
                }

                &.is-active {
                    border-color: #868686;
                    background-color: #868686;

                    .btn__name {
                        font-weight: 700;
                        color: #ffffff;
                    }
                }

                &__name {
                    padding: 11px 19px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 100%;
                    color: #868686;
                    text-transform: none;

                    @include is-screen-max($breakpoint-md) {
                        padding: 8px 12px;
                        font-size: 12px;
                    }
                }
            }
        }

        &__body {
            &:not(.is-active) {
                display: none !important;
            }

            .tabs-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px 60px;

                @include is-screen-max($breakpoint-lg) {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }

                @include is-screen-max($breakpoint-md) {
                    gap: 10px;
                }

                &__item {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: flex-end;
                    gap: 5px;

                    p {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 110%;
                        color: #363636;

                        @include is-screen-max($breakpoint-md) {
                            font-size: 14px;
                        }

                        &:first-child {
                            display: flex;
                            flex-grow: 1;
                            gap: 5px;

                            &::after {
                                content: "";
                                flex: 1 1 0;
                                border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
                                margin-bottom: 3px;
                            }
                        }

                        &:last-child {
                            margin-left: auto;
                            color: #686b6c;
                        }
                    }
                }
            }
        }
    }

    &__download {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .related {
        margin-top: 40px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        padding-top: 70px;

        &__title {
            margin-bottom: 40px;
            font-weight: 400;
            font-size: 18px;
            line-height: 130%;
            color: #003201;
            text-transform: uppercase;
        }

        &__grid {
            .grid__item {
                @include is-screen-max($breakpoint-xl) {
                    &:nth-child(4) {
                        display: none;
                    }
                }

                @include is-screen-max($breakpoint-lg) {
                    &:nth-child(4) {
                        display: flex;
                    }
                }
            }
        }
    }
}
