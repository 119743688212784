/**********************************************************************/
/**********************************************************************/
.wysiwyg {
	max-width: 100%;
	font-size: 16px;
	line-height: 24px;

	&.align {
		&-center {
			text-align: center;
		}
		&-left {
			text-align: left;
		}
		&-right {
			text-align: right;
		}
	}

	p {
		margin-bottom: 30px;

		& + p {
			margin-top: -10px;
		}
	}

	a {
		color: #003201;
		text-decoration: underline;
		transition: opacity 0.3s;

		&:hover {
			opacity: 0.6;
		}
	}

	img {
		height: auto;
		margin-bottom: 30px;

		&.align {
			&-center {
				float: none;
				margin-left: auto;
				margin-right: auto;
			}
			&-left {
				float: left;
				margin-right: 30px;
			}
			&-right {
				float: right;
				margin-left: 30px;
			}
		}
	}

	strong, b {
		font-weight: 700;
	}

	h1, h2, h3, h4, h5, h6 {
		margin-bottom: 30px;
		line-height: 130%;
	}

	h1, h2 {
		font-weight: 700;
		font-size: 20px;
		text-transform: uppercase;
	}

	h3, h4 {
		font-weight: 500;
		font-size: 18px;
	}

	h5, h6 {
		font-weight: 400;
		font-size: 16px;
	}

	ul, ol {
		margin-bottom: 30px;

		li {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}


	/******************************************************************/
	/******************************************************************/
	@include is-screen-max($breakpoint-md) {
		font-size: 14px;
		line-height: 20px;

		p {
			margin-bottom: 20px;

			& + p {
				margin-top: -5px;
			}
		}

		img {
			margin-bottom: 20px;

			&.align {
				&-left {
					margin-right: 20px;
				}
				&-right {
					margin-left: 20px;
				}
			}
		}

		h1, h2, h3, h4, h5, h6 {
			margin-bottom: 20px;
		}

		h1, h2 {
			font-size: 18px;
		}

		h3, h4 {
			font-size: 16px;
		}

		h5, h6 {
			font-size: 14px;
		}

		ul, ol {
			margin-bottom: 20px;

			li {
				margin-bottom: 5px;
			}
		}
	}


	/******************************************************************/
	/******************************************************************/
	@include is-screen-max($breakpoint-sm) {
		img {
			float: none !important;
			margin-left: auto !important;
			margin-right: auto !important;
		}
	}
}