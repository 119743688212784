/**********************************************************************/
/**********************************************************************/
.page-contacts {
    margin-bottom: 190px;

    &__titlearea {
        width: calc(100% - 290px);
        margin: 50px 0 20px auto;
    }

    &__title {
        width: 650px;
        max-width: 100%;
        margin: 20px auto 0 0;
        font-weight: 300;
        font-size: 36px;
        line-height: 130%;
        color: #003201;
        text-transform: uppercase;
    }

    /******************************************************************/
    &__body {
        display: flex;
        justify-content: space-between;

        .sidebar {
            &-blocks {
                display: flex;
                flex-direction: column;
            }

            &-block {
                display: flex;
                flex-direction: column;
                margin-bottom: 30px;

                &__title {
                    padding: 17px 0 13px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                    font-size: 16px;
                    line-height: 20px;
                    color: #424242;
                    text-transform: uppercase;
                }

                &__content {
                    display: flex;
                    flex-direction: column;
                    margin-top: 15px;

                    p {
                        font-size: 16px;
                        line-height: 150%;
                        color: #424242;
                        word-break: break-word;

                        &.sm {
                            font-size: 12px;
                        }
                    }

                    a {
                        margin-top: 8px;
                        margin-right: auto;
                        font-size: 16px;
                        line-height: 150%;
                        color: #448a33;
                        transition: opacity 0.3s;

                        &:hover {
                            opacity: 0.6;
                        }

                        & + a {
                            margin-top: 0;
                        }
                    }
                }

                &__socials {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    margin-top: 5px;

                    img {
                        max-height: 20px;
                    }
                }
            }
        }
    }

    /******************************************************************/
    &__content {
        position: relative;
        width: calc(100% - 290px);
        padding-top: 55px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    &__formarea {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 740px;
        max-width: 100%;
        padding: 70px 40px 80px;
        border-radius: 10px;
        box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.15);
        background-color: #ffffff;
        overflow: hidden;

        .formarea {
            width: 580px;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-lg) {
        &__body {
            .sidebar {
                display: flex;
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-md) {
        margin-bottom: 60px;

        &__titlearea {
            width: 100%;
            margin-top: 20px;
        }

        &__title {
            width: 100%;
            font-size: 24px;
        }

        /**************************************************************/
        &__body {
            flex-direction: column;

            .sidebar {
                width: 100%;
                margin-top: 0px;

                &-block {
                    margin-bottom: 20px;

                    &__title {
                        padding: 12px 0 8px;
                        font-size: 14px;
                    }

                    &__content {
                        p {
                            font-size: 14px;

                            &.sm {
                                font-size: 12px;
                            }
                        }

                        a {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        /*************************************************************/
        &__content {
            width: 100%;
            padding-top: 30px;

            &:before {
                width: 100%;
            }
        }

        &__formarea {
            width: 100%;
            padding: 30px 20px 40px;
        }
    }
}
