/**********************************************************************/
/**********************************************************************/
.page-account {
    margin-bottom: 190px;

    &__titlearea {
        position: relative;
        width: calc(100% - 290px);
        margin: 50px 0 20px auto;
    }

    &__title {
        width: 650px;
        max-width: 100%;
        margin: 20px auto 0 0;
        font-weight: 300;
        font-size: 36px;
        line-height: 130%;
        color: #003201;
        text-transform: uppercase;
    }

    /******************************************************************/
    &__body {
        display: flex;
        justify-content: space-between;
    }

    /******************************************************************/
    .bonus-points {
        display: flex;
        margin-top: 25px;

        &__num {
            margin: auto 15px auto 0;
            font-weight: 300;
            font-size: 24px;
            line-height: 30px;
            color: #448a33;
        }

        &__txt {
            margin: auto 0;
            font-size: 10px;
            line-height: 12px;
            color: #787878;
            text-transform: uppercase;
        }
    }

    /******************************************************************/
    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: calc(100% - 290px);
        padding-top: 55px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.2);
        }

        /**************************************************************/
        /**************************************************************/
        .formarea {
            width: 590px;
            margin-top: -25px;
        }
    }

    /******************************************************************/
    /******************************************************************/
    .account-bonus {
        /**************************************************************/
        .page-account__content {
            &:before {
                width: 100%;
            }
        }

        /**************************************************************/
        &__program {
            margin: -10px 0 20px auto;
            font-size: 14px;
            line-height: 20px;
            color: #448a33;
            transition: opacity 0.3s;

            &:hover {
                opacity: 0.6;
            }
        }

        /**************************************************************/
        &__row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: calc(100% + 20px);
            margin: 0 -10px;
            padding-bottom: 30px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }

        &__name {
            margin: 0 10px 20px;
            font-size: 14px;
            line-height: 20px;
            color: #787878;
            text-transform: uppercase;

            span {
                margin-left: 10px;
                font-size: 16px;
                color: #000000;
                text-transform: initial;

                &.green {
                    color: #226223;
                }
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-lg) {
        .sidebar {
            display: flex;
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-md) {
        margin-bottom: 60px;

        &__titlearea {
            width: 100%;
            margin-top: 20px;
        }

        &__title {
            width: 100%;
            font-size: 24px;
        }

        /**************************************************************/
        &__body {
            flex-direction: column;

            .sidebar {
                width: 100%;
                margin-top: 0px;
            }
        }

        /**************************************************************/
        &__content {
            width: 100%;
            padding-top: 30px;

            /**********************************************************/
            /**********************************************************/
            .formarea {
                width: 590px;
                margin: 0 auto;
            }
        }

        /**************************************************************/
        .bonus-points {
            margin-top: 10px;
            margin-bottom: 20px;
        }

        /**************************************************************/
        /**************************************************************/
        .account-bonus {
            /**********************************************************/
            &__program {
                margin: 0 auto 20px 0;
            }

            /**********************************************************/
            &__row {
                flex-direction: column;
            }
        }
    }
}
