/**********************************************************************/
/**********************************************************************/
.page-cart {
    margin-bottom: 190px;

    &__titlearea {
        margin: 50px 0 30px;
    }

    &__title {
        margin: 20px 0 0;
        font-weight: 300;
        font-size: 36px;
        line-height: 130%;
        color: #003201;
        text-transform: uppercase;
    }

    /******************************************************************/
    .cart {
        &__items {
            display: flex;
            flex-direction: column;
        }

        &-item {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            padding: 50px 30px 40px 0;
            border-top: 1px solid rgba(0, 0, 0, 0.2);

            &__main {
                .row {
                    flex-wrap: nowrap;
                }
            }

            &__img {
                display: flex;
                flex-direction: column;
                width: 210px;
                transition: opacity 0.3s;

                /******************************************************/
                @include is-screen-max($breakpoint-lg) {
                    width: 140px;
                }

                &:hover {
                    opacity: 0.6;
                }

                img {
                    max-height: 100%;
                    margin: auto;
                }
            }

            &__body {
                display: flex;
                flex-direction: column;
                width: 200px;
                max-width: 100%;
                margin-left: 30px;

                /******************************************************/
                @include is-screen-max($breakpoint-lg) {
                    width: 160px;
                    margin-left: 10px;
                }
            }

            &__title {
                margin-right: auto;
                margin-bottom: 25px;
                font-weight: 300;
                font-size: 18px;
                line-height: 110%;
                color: #003201;
                text-transform: uppercase;
                transition: opacity 0.3s;

                &:hover {
                    opacity: 0.6;
                }
            }

            &__descr {
                display: flex;
                flex-direction: column;

                p {
                    margin-bottom: 10px;
                    font-size: 14px;
                    line-height: 110%;
                    color: #363636;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    span {
                        margin-left: 5px;
                        color: #787878;
                    }
                }
            }

            &__price {
                font-size: 16px;
                line-height: 24px;
                color: #868686;
                text-align: center;
            }

            &__price-disc {
                font-size: 16px;
                line-height: 24px;
                color: #363636;
                text-align: center;
            }

            &__price-final {
                font-size: 20px;
                line-height: 24px;
                color: #448a33;
                text-align: center;
            }

            &__remove {
                position: absolute;
                top: 45px;
                right: 0;
                width: 20px;
                height: 20px;
                transition: opacity 0.3s;
                cursor: pointer;

                &:hover {
                    opacity: 0.6;
                }

                &:before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(-45deg);
                    width: 20px;
                    height: 2px;
                    border-radius: 1px;
                    background-color: #000000;
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                    width: 20px;
                    height: 2px;
                    border-radius: 1px;
                    background-color: #000000;
                }
            }
        }

        &-col {
            display: flex;
            flex-direction: column;

            &__title {
                margin-bottom: 30px;
                font-size: 14px;
                line-height: 110%;
                color: #787878;
                text-transform: uppercase;
                text-align: center;
            }

            &__body {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        .quantity {
            position: relative;
            display: flex;
            margin-left: auto;
            margin-right: auto;

            &::after {
                content: "уп";
                position: absolute;
                top: 50%;
                right: 40px;
                transform: translateY(-50%);
                font-weight: 400;
                font-size: 12px;
                line-height: 100%;
                color: #868686;
                pointer-events: none;
            }

            &__btn {
                position: relative;
                width: 30px;
                height: 30px;
                border: 1px solid #bfbfbf;
                border-radius: 50%;
                transition: background-color 0.3s;
                cursor: pointer;

                &:hover {
                    background-color: #e5e5e5;
                }

                &:before {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    font: normal 400 20px / 100% "Muller", sans-serif;
                    color: #787878;
                }

                &.minus {
                    &:before {
                        content: "-";
                        top: 3px;
                    }
                }

                &.plus {
                    &:before {
                        content: "+";
                        top: 4px;
                    }
                }
            }

            input {
                width: 60px;
                padding: 0 30px 0 0;
                border: none;
                background-color: transparent;
                font: normal 500 15px / 30px "Muller", sans-serif;
                color: #363636;
                text-align: right;
                outline: none;
                pointer-events: none;
                -moz-appearance: textfield;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    margin: 0;
                    -webkit-appearance: none;
                }
            }
        }

        /**************************************************************/
        &__coupon-area {
            display: flex;
            flex-direction: column;
            padding: 30px 0;
            border-top: 1px solid rgba(0, 0, 0, 0.2);

            .coupon {
                display: flex;
                margin-left: auto;

                input {
                    width: 170px;
                    height: 55px;
                    padding: 0 10px;
                    border: 1px solid #c4c4c4;
                    border-right: none;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    font: normal 400 12px / 55px "Muller", sans-serif;
                    color: #787878;
                    text-transform: uppercase;
                    text-align: center;
                    outline: none;

                    &::placeholder {
                        color: rgba(0, 0, 0, 0.2);
                    }
                }

                button {
                    width: 170px;
                    height: 55px;
                    border: none;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    background-color: #dedede;
                    font: normal 400 12px / 55px "Muller", sans-serif;
                    color: #ffffff;
                    text-transform: uppercase;
                    text-align: center;
                    transition: background-color 0.3s;
                    cursor: pointer;

                    &:hover {
                        background-color: #787878;
                    }
                }
            }
        }

        /**************************************************************/
        &__btmarea {
            display: flex;
            justify-content: space-between;
            padding-top: 30px;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
        }

        &__back {
            position: relative;
            display: flex;
            margin: 0 auto auto 0;
            padding-left: 25px;
            transition: opacity 0.3s;

            &:hover {
                opacity: 0.6;
            }

            img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-60%);
                width: 22px;
            }

            span {
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                color: #787878;
            }
        }

        &-final {
            display: flex;
            flex-direction: column;
            width: 220px;

            &__title {
                margin: 0 auto 20px;
                font-size: 14px;
                line-height: 110%;
                color: #787878;
                text-transform: uppercase;
                text-align: center;
            }

            &__summ {
                margin: 0 auto 30px;
                font-size: 24px;
                line-height: 110%;
                color: #448a33;
                text-align: center;
            }

            &__btn {
                display: block;
                width: 100%;
                height: 50px;
                max-width: 100%;
                border: 4px solid transparent;
                border-radius: 25px;
                background-color: #226223;
                font: normal 700 14px / 42px "Muller", sans-serif;
                color: #ffffff;
                text-transform: uppercase;
                text-align: center;
                cursor: pointer;
                transition: border-color 0.3s;

                &:hover {
                    border-color: #199629;
                }
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-md) {
        margin-bottom: 60px;

        &__titlearea {
            margin: 20px 0;
        }

        &__title {
            font-size: 24px;
        }

        /**************************************************************/
        .cart {
            &-item {
                flex-direction: column;
                padding: 40px 0 20px;

                &__main {
                    width: 100%;
                    margin-bottom: 30px;
                }

                &__body {
                    width: calc(100% - 160px);
                    margin-left: 0;
                }

                &__title {
                    margin-bottom: 15px;
                    font-size: 16px;
                }

                &__price {
                    font-size: 16px;
                    line-height: 20px;
                }

                &__price-disc {
                    font-size: 16px;
                    line-height: 20px;
                }

                &__price-final {
                    font-size: 18px;
                    line-height: 20px;
                }

                &__remove {
                    top: 10px;
                }
            }

            &-col {
                flex-direction: row !important;
                margin-bottom: 20px;

                &__title {
                    width: 150px;
                    margin: auto 20px auto 0;
                    font-size: 14px;
                    text-align: left;
                }

                &__body {
                    justify-content: flex-start;
                    margin: auto 0;
                }
            }

            /**********************************************************/
            &__coupon-area {
                padding: 20px 0;

                .coupon {
                    margin-right: auto;

                    input {
                        width: 140px;
                        height: 45px;
                        line-height: 45px;
                    }

                    button {
                        width: 140px;
                        height: 45px;
                        line-height: 45px;
                    }
                }
            }

            /**********************************************************/
            &__btmarea {
                flex-direction: column;
                padding-top: 20px;
            }

            &__back {
                margin: 0 auto 30px;
            }

            &-final {
                width: 220px;
                margin: 0 auto;

                &__title {
                    margin-bottom: 10px;
                }

                &__summ {
                    margin-bottom: 20px;
                    font-size: 20px;
                }

                &__btn {
                    height: 45px;
                    line-height: 37px;
                }
            }
        }
    }
}
