/**********************************************************************/
/**********************************************************************/
.page-catalog {
    margin-bottom: 190px;

    @include is-screen-max($breakpoint-md) {
        margin-bottom: 60px;
    }

    &__titlearea {
        margin-top: 50px;
        margin-bottom: 20px;

        @include is-screen-max($breakpoint-lg) {
            width: 100%;
        }

        @include is-screen-max($breakpoint-md) {
            margin-top: 16px;
            margin-bottom: 10px;
        }
    }

    &__title {
        margin-top: 20px;
        font-weight: 300;
        font-size: 36px;
        line-height: 130%;
        color: #003201;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-md) {
            margin-top: 16px;
            font-size: 24px;
            line-height: 120%;
        }
    }

    &__nav {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 30px;
        margin-bottom: 10px;
        background-color: #ffffff;
        z-index: 200;

        @include is-screen-min($breakpoint-lg) {
            position: sticky;
            top: 120px;
        }

        @include is-screen-max($breakpoint-md) {
            padding-bottom: 20px;
        }

        .grid-categories {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;

            &__button {
                display: flex;
                align-items: center;
                flex-shrink: 0;
                gap: 10px;
                padding: 8px 15px;
                border: 1px solid #bfbfbf;
                border-radius: 50px;
                background-color: transparent;
                transition: background-color 0.3s, border-color 0.3s;
                cursor: pointer;

                &:hover {
                    @include is-screen-min($breakpoint-md) {
                        background-color: #dedede;
                        border-color: #dedede;

                        svg {
                            path {
                                fill: #424242;
                            }
                        }

                        p {
                            color: #424242;
                        }
                    }
                }

                &.is-active {
                    background-color: #868686;
                    border-color: #868686;

                    svg {
                        path {
                            fill: #ffffff;
                        }
                    }

                    p {
                        font-weight: 500;
                        color: #ffffff;
                    }
                }

                svg,
                img {
                    height: 32px;

                    path {
                        transition: fill 0.3s;
                    }
                }

                p {
                    font-size: 14px;
                    line-height: 100%;
                    color: #868686;
                    transition: color 0.3s;
                }
            }
        }

        .grid-tags {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;

            &__button {
                padding: 11px 19px;
                border: 1px solid #40b04e;
                border-radius: 20px;
                background-color: transparent;
                transition: background-color 0.3s, border-color 0.3s;
                cursor: pointer;

                &:hover {
                    @include is-screen-min($breakpoint-md) {
                        background-color: #40b04e;
                        border-color: #40b04e;

                        p {
                            color: #ffffff;
                        }
                    }
                }

                &.is-active {
                    background-color: #226223;
                    border-color: #226223;

                    p {
                        color: #ffffff;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 100%;
                    color: #40b04e;
                    white-space: nowrap;
                    transition: color 0.3s;
                }
            }
        }
    }

    &__body {
        display: flex;
        justify-content: space-between;

        @include is-screen-max($breakpoint-lg) {
            flex-direction: column;
        }

        .sidebar {
            margin-top: -10px;

            @include is-screen-max($breakpoint-lg) {
                display: flex;
                width: 100%;
                margin-top: 0;
                margin-bottom: 40px;
            }

            @include is-screen-max($breakpoint-md) {
                margin-bottom: 0;
            }

            &__toggler {
                display: none;
                width: 320px;
                min-height: 50px;
                margin: 0 auto;

                @include is-screen-max($breakpoint-lg) {
                    display: flex;
                }

                @include is-screen-max($breakpoint-md) {
                    display: none;
                    min-height: 40px;
                }

                span {
                    &:last-child {
                        display: none;
                    }
                }
            }

            &.open {
                .sidebar__toggler {
                    span {
                        &:first-child {
                            display: none;
                        }
                        &:last-child {
                            display: block;
                        }
                    }
                }
            }

            &__wrap {
                display: flex;
                flex-direction: column;

                @include is-screen-min($breakpoint-lg) {
                    display: flex !important;
                }

                @include is-screen-max($breakpoint-lg) {
                    display: none;
                    margin-top: 20px;
                }

                @include is-screen-max($breakpoint-md) {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    display: flex !important;
                    margin-top: 0;
                    padding: 50px;
                    background-color: #ffffff;
                    opacity: 0;
                    pointer-events: none;
                    overflow: hidden auto;
                    z-index: 1250;
                    transition: opacity 0.3s;

                    &.show {
                        opacity: 1;
                        pointer-events: initial;
                    }
                }

                &-title {
                    margin-bottom: 20px;
                    font-weight: 300;
                    font-size: 24px;
                    line-height: 100%;
                    color: #003201;
                    text-transform: uppercase;

                    @include is-screen-min($breakpoint-md) {
                        display: none;
                    }
                }

                &-close {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    width: 20px;
                    height: 20px;
                    transition: opacity 0.3s;
                    cursor: pointer;

                    @include is-screen-min($breakpoint-md) {
                        display: none;
                    }

                    &:hover {
                        opacity: 0.6;
                    }

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 20px;
                        height: 2px;
                        border-radius: 1px;
                        background-color: #000000;
                    }

                    &::before {
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }

                    &::after {
                        transform: translate(-50%, -50%) rotate(45deg);
                    }
                }
            }

            &__items {
                display: flex;
                flex-direction: column;
                padding-bottom: 20px;

                @include is-screen-max($breakpoint-lg) {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }

                @include is-screen-max($breakpoint-md) {
                    flex-direction: column;
                }
            }

            &__item {
                @include is-screen-max($breakpoint-lg) {
                    width: calc(50% - 10px);
                }

                @include is-screen-max($breakpoint-md) {
                    width: 100%;
                }
            }

            &__btn {
                width: 100%;
                min-height: 50px;
                margin: 10px 0 0;
                border-radius: 25px;

                @include is-screen-max($breakpoint-md) {
                    &__btn {
                        width: 320px;
                        max-width: 100%;
                        min-height: 40px;
                        margin: 30px auto 0;
                        border-radius: 20px;
                    }
                }
            }
        }

        .catalog-grid {
            width: calc(100% - 290px);

            @include is-screen-max($breakpoint-lg) {
                width: 100%;
            }

            .grid-head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 48px;
                margin-bottom: 10px;

                @include is-screen-max($breakpoint-md) {
                    gap: 30px;
                    margin-bottom: 20px;
                }

                &__filter {
                    position: relative;
                    display: block;
                    flex: 1 1 0;
                    margin: auto 0;
                    padding: 5px 18px 5px 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                    background-color: #ffffff;
                    font-size: 14px;
                    line-height: 20px;
                    color: #787878;
                    transition: opacity 0.3s;
                    cursor: pointer;

                    img {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 10px;
                    }

                    @include is-screen-min($breakpoint-md) {
                        display: none;
                    }
                }

                &__selects {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex: 1 1 0;
                    gap: 0 20px;
                    flex-wrap: wrap;

                    .grid-select {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        &__label {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: normal;
                            color: #787878;
                            text-transform: uppercase;

                            @include is-screen-max($breakpoint-md) {
                                display: none;
                            }
                        }

                        &__body {
                            .select {
                                &__selected {
                                    padding: 5px 18px 5px 0;
                                    color: #787878;

                                    img {
                                        right: 0;
                                        width: 10px;
                                    }
                                }

                                &__items {
                                    right: initial;
                                    display: flex;
                                    flex-direction: column;
                                    gap: 6px;
                                    width: 150px;
                                    padding: 15px 0;
                                    box-shadow: 0px 0px 20px 0px
                                    rgba(0, 0, 0, 0.15);
                                }

                                &__item {
                                    padding: 5px 20px;
                                    font-family: "Muller", sans-serif;
                                    font-size: 14px;
                                    line-height: 100%;
                                    color: #686b6c !important;
                                    transition: background-color 0.3s;

                                    &:hover {
                                        background-color: #f3f3f3;
                                    }

                                    &.same {
                                        display: initial;
                                        background-color: #f3f3f3;
                                    }
                                }
                            }
                        }

                        @include is-screen-max($breakpoint-md) {
                            width: 100%;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.2);

                            &__body {
                                width: 100%;
                            }

                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }

                .grid-toggle {
                    display: flex;
                    align-items: center;
                    gap: 14px;
                    flex-shrink: 0;

                    @include is-screen-max($breakpoint-md) {
                        display: none;
                    }

                    &__icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        background-color: #f3f3f3;
                        transition: background-color 0.3s;
                        cursor: pointer;

                        &.is-active {
                            background-color: #dedede;
                        }

                        &:hover {
                            @include is-screen-min($breakpoint-md) {
                                background-color: #dedede;
                            }
                        }

                        img {
                            width: 24px;
                        }
                    }
                }
            }

            .grid {
                position: relative;
                padding-top: 40px;
                padding-bottom: 40px;
                margin-bottom: 40px;

                @include is-screen-max($breakpoint-md) {
                    padding-top: 20px;
                    padding-bottom: 0;
                }

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: rgba(0, 0, 0, 0.2);

                    @include is-screen-max($breakpoint-md) {
                        display: none;
                    }
                }

                &::after {
                    top: initial;
                    bottom: 0;
                }

                & > .row {
                    margin-bottom: 40px;

                    @include is-screen-max($breakpoint-md) {
                        width: calc(100% + 30px);
                        margin-left: -15px;
                        margin-right: -15px;
                        margin-bottom: 30px;
                    }

                    .col {
                        @include is-screen-max($breakpoint-md) {
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                    }
                }

                &.is-rows {
                    @include is-screen-min($breakpoint-md) {
                        padding-top: 30px;
                    }
                }
            }

            .catalog-description {
                display: flex;
                flex-direction: column;

                &__title {
                    margin-bottom: 25px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: normal;
                    color: #003201;
                    text-transform: uppercase;
                }
            }
        }
    }
}
