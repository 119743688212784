/**********************************************************************/
/**********************************************************************/
.page-404 {
  margin-bottom: 190px;

  .container__content {
    display: flex;
    flex-direction: column;
  }

  /******************************************************************/
  &__img {
    width: 330px;
    margin: 80px auto 45px;
  }

  &__title {
    margin: 0 auto 10px;
    font-weight: 300;
    font-size: 48px;
    line-height: 110%;
    color: #003201;
    text-transform: uppercase;
    text-align: center;
  }

  &__subtitle {
    margin: 0 auto 30px;
    font-size: 24px;
    line-height: 40px;
    color: #02482f;
    text-align: center;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  &__btn {
    margin: 0 auto;
  }

  /******************************************************************/
  /******************************************************************/
  @include is-screen-max($breakpoint-lg) {
    &__img {
      width: 250px;
      margin-top: 60px;
      margin-bottom: 35px;
    }

    &__title {
      margin-bottom: 0;
      font-size: 40px;
    }

    &__subtitle {
      font-size: 20px;
      line-height: 30px;
    }
  }

  /******************************************************************/
  /******************************************************************/
  @include is-screen-max($breakpoint-md) {
    margin-bottom: 60px;

    &__img {
      width: 200px;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    &__title {
      font-size: 30px;
    }

    &__subtitle {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 24px;
    }

    &__btns {
      gap: 10px;
    }
  }
}
