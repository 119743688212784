@import "meta";
@import "mapbox-gl";

.formarea__input {
    position: relative;
}

.focus_label {
    position: absolute;
    color: #40B04E;
    background: #fff;
    top: -8px;
    left: 8px;
    padding: 0 4px;
    z-index: 1;
}

.pointer {
    cursor: pointer;
}

.breadcrumbs {
    list-style: none;
    padding: 0;
}

.catalog-paginator {
    justify-content: space-between !important;

    .paginator-info {
        text-align: left;
        display: flex;
        align-items: center;
        color: #404040;
    }

    .paginator-links {
        nav {
            ul.pagination {
                display: flex;
                list-style: none;
                justify-content: flex-end;

                li.page-item {
                    margin-right: 5px;

                    &.active {
                        span.page-link {
                            color: #3fb14e;
                        }
                    }

                    &.disabled {
                        span.page-link {
                            color: #dfdede;
                        }
                    }

                    span.page-link, a.page-link {
                        display: block;
                        width: 30px;
                        height: 30px;
                        padding: 5px 0;
                        text-align: center;
                        transition: 300ms ease-in;
                        font-size: 14px;

                        &:hover {
                            color: #3fb14e;
                        }
                    }

                    a.page-link {
                        color: #404040;
                    }
                }
            }
        }
    }

}

.blur_element {
    filter: blur(2px);
}

table {

    width: 100%;
    margin-bottom: 1rem;
    color: #858796;
    border-collapse: collapse;

    thead {
        tr {
            th {
                vertical-align: middle;
                border-bottom: 2px solid #e3e6f0;
                padding: 0.3rem;
            }
        }
    }

    tbody {
        tr {
            td {
                vertical-align: middle;
                border-bottom: 1px solid #e3e6f0;
                padding: 0.3rem;
            }
        }
    }
}

.admin_bar {
    background: #1b8f70;
    padding: 10px;
    color:#fff;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #fff;

    @media screen and (max-width: 768px) {
        display: none;
    }


    .admin_nav {
        a {
            color: #efefef;
            margin: 0 5px;

            &:hover {
                color:#fff;
                text-decoration: underline;
            }
        }
    }

}

.d-none {
    display: none!important;
}

.block_reloading {
    opacity: 0.6;
    position: relative;
}

.block_loader {
    top: 0;
    position: absolute;
    z-index: 777;
    width: 100%;
    height: 100%;
    background: url(../../../media/loader.gif) top center no-repeat;
    opacity: 1;
}

.account-documents {
    .document_link {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
        color: #787878;
        text-transform: uppercase;
        transition: opacity .3s;
        opacity: .6;

        &:hover {
            opacity: 1;
        }
    }
}

.footer__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 60px;

    .footer-list {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
    }

    .footer-list__item {
        margin-bottom: 15px;
        margin-right: auto;
        transition: opacity 0.3s;
        display: flex;
        padding: 0;

        .content {
            display: flex;
            padding-left: 30px;
            position: relative;

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 20px;
            }
        }

        .socials {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-left: 10px;
            flex-direction: row;

            a {
                font-size: 14px;
                line-height: 40px;
                color: #ffffff;
                transition: opacity 0.3s;

                img {
                    max-height: 20px;
                }
            }
        }
    }
}

.page-contacts__body {
    .sidebar-block__content {
        .sidebar-block__socials {
            a {
                margin: 0;
            }
        }
    }
}

.page-catalog__nav .grid-categories__button {
    &.is-active, &:hover {
        img {
            filter: brightness(0) invert(1);
        }
    }
}

.wysiwyg {
    iframe {
        width: 100% !important;
        margin: 20px 0 20px 0;
    }
}

.alert {
    p {
        font-size: 14px;
        padding: 15px;
        margin: 10px 0;
    }

    .success {
        background: #dcefdd;
        color: #474747;
    }

    .error {
        background: #e78a86;
        color: #fff;
    }
}

.formarea__input {
    input {
        &:disabled {
            background: #e5e5e5;
        }
    }
}

button {
    &:disabled {
        opacity: 0.1;
        cursor: default !important;
    }
}
