/**********************************************************************/
/**********************************************************************/
.ranger {
	&__input {
		display: none;
	}

	&__vals {
		display: flex;
		justify-content: space-between;
		padding: 20px 0 15px;
	}

	&__val {
		font-size: 14px;
		line-height: 14px;
		color: #787878;
		text-align: center;
	}

	&__line {
		display: flex;
		justify-content: space-between;
	}

	&__track {
		position: relative;
		width: 100%;
	    height: 2px;
		margin: auto 0;
		border-radius: 1px;
	    background-color: #BFBFBF;

	    .ui-slider {
	    	&-range {
				position: absolute;
			    top: 0px;
			    width: 100%;
			    height: 2px;
			    background-color: #868686;
			}

			&-handle {
				position: absolute;
			    top: -3px;
			    display: block;
			    width: 8px;
			    height: 8px;
			    margin-left: -4px;
			    border-radius: 50%;
			    background-color: #787878;;
			    outline: none;
			    cursor: pointer;
			}
		}
	}
}