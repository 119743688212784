.page-vacancy {
    width: 915px;
    max-width: 100%;
    margin: 0 auto 90px;

    @include is-screen-max($breakpoint-md) {
        margin-bottom: 50px;
    }

    & > .container {
        &__content {
            display: flex;
            flex-direction: column;
        }
    }

    &__titlearea {
        width: 100%;
        margin-top: 50px;

        @include is-screen-max($breakpoint-md) {
            margin-top: 20px;
        }

        .breadcrumbs {
            margin-bottom: 20px;
        }
    }

    &__title {
        font-weight: 300;
        font-size: 36px;
        line-height: 100%;
        color: #003201;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-md) {
            font-size: 24px;
        }
    }

    &__back {
        display: flex;
        align-items: center;
        gap: 6px;
        align-self: flex-end;
        transition: opacity 0.3s;

        @include is-screen-max($breakpoint-md) {
            margin-top: 10px;
        }

        &:hover {
            opacity: 0.6;
        }

        img {
            height: 11px;
        }

        p {
            font-size: 16px;
            line-height: 100%;
            color: #787878;

            @include is-screen-max($breakpoint-md) {
                font-size: 14px;
            }
        }
    }

    &__vacancy {
        margin-top: 14px;
        margin-bottom: 40px;

        @include is-screen-max($breakpoint-md) {
            margin-bottom: 30px;
        }
    }

    .vacancy-body {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-bottom: 60px;

        @include is-screen-max($breakpoint-md) {
            gap: 30px;
            margin-bottom: 30px;
        }

        &__title {
            font-weight: 300;
            font-size: 24px;
            line-height: 36px;
            color: #448a33;
            text-transform: uppercase;

            @include is-screen-max($breakpoint-md) {
                font-size: 18px;
                line-height: 24px;
            }
        }

        &__content {
            color: #363636;
        }
    }

    .vacancy-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        @include is-screen-max($breakpoint-md) {
            gap: 20px;
        }

        &__title {
            font-size: 16px;
            line-height: 24px;
            color: #448a33;
            text-transform: uppercase;
            text-align: center;
        }

        &__content {
            display: flex;
            gap: 20px;
            width: 100%;

            @include is-screen-max($breakpoint-md) {
                flex-direction: column;
                gap: 30px;
            }

            .vacancy-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1 1 0;
                gap: 16px;

                @include is-screen-max($breakpoint-md) {
                    gap: 8px;
                }

                &__title {
                    font-size: 14px;
                    line-height: 100%;
                    text-transform: uppercase;
                    text-align: center;
                }

                &__card {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 30px;
                    width: 100%;
                    min-height: 120px;
                    padding: 20px;
                    border-radius: 10px;
                    background-color: #f6f6f6;

                    @include is-screen-max($breakpoint-md) {
                        min-height: 90px;
                    }

                    a {
                        display: flex;
                        align-items: center;
                        gap: 9px;
                        transition: opacity 0.3s;

                        &:hover {
                            opacity: 0.6;
                        }

                        img {
                            height: 22px;
                        }

                        p {
                            font-size: 13px;
                            line-height: 17px;
                            color: #424242;
                        }
                    }
                }
            }
        }

        &__back {
            min-height: initial;
            min-width: initial;
            margin: 10px auto 0;

            .btn {
                &__name {
                    padding: 16px 63px 14px;
                    font-size: 12px;
                    line-height: 100%;

                    @include is-screen-max($breakpoint-md) {
                        padding: 12px 40px 10px;
                        border-radius: 30px;
                    }
                }
            }
        }
    }
}
