.vacancy {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    @include is-screen-max($breakpoint-md) {
        padding: 30px 0;
    }

    &__content {
        display: flex;
        align-items: flex-start;
        gap: 60px;

        @include is-screen-max($breakpoint-md) {
            flex-direction: column;
            gap: 20px;
        }
    }

    &__name {
        font-size: 20px;
        line-height: 100%;
        color: #363636;

        @include is-screen-max($breakpoint-md) {
            font-size: 18px;
        }
    }

    .vacancy-meta {
        display: flex;
        gap: 20px 60px;
        flex-wrap: wrap;
        flex-shrink: 0;

        @include is-screen-max($breakpoint-md) {
            gap: 20px;
        }

        &__item {
            display: flex;
            align-items: flex-end;
            gap: 8px;
            max-width: 200px;
            min-height: 20px;

            @include is-screen-max($breakpoint-md) {
                max-width: initial;
            }

            img {
                height: 20px;
            }

            p {
                font-size: 16px;
                line-height: 100%;
                color: #787878;
            }

            &--salary {
                p {
                    font-size: 16px;
                    line-height: 100%;
                    color: #448a33;

                    span {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    &__more {
        align-self: flex-end;
        min-height: initial;
        min-width: initial;
        margin-right: 0;

        .btn {
            &__name {
                padding: 10px 20px 8px;
                font-size: 12px;

                @include is-screen-max($breakpoint-md) {
                    padding: 8px 20px 6px;
                    border-radius: 30px;
                }
            }
        }
    }
}
