/**********************************************************************/
/**********************************************************************/
.accordion {
	position: relative;

	&__head {
		position: relative;
		padding: 15px 25px 15px 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		transition: opacity 0.3s;
		cursor: pointer;

		p {
			font-size: 14px;
			line-height: 20px;
			color: #787878;
			text-transform: uppercase;
		}

		&:before, &:after {
			content: "";
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			border-radius: 1px;
			background-color: #787878;
			transition: opacity 0.3s;
		}

		&:before {
			right: 0;
			width: 12px;
			height: 2px;
		}

		&:after {
			right: 5px;
			width: 2px;
			height: 12px;
		}

		&:hover {
			opacity: 0.6;
		}
	}

	&__body {
		display: none;
		margin: 10px 0;
	}

	&.open {
		.accordion__head:after {
			opacity: 0;
		}
	}


	/******************************************************************/
	/******************************************************************/
	@include is-screen-max($breakpoint-md) {
		&__head {
			padding: 10px 15px 10px 0;

			p {
				font-size: 18px;
				text-transform: initial;
			}

			&:before {
				width: 10px;
			}

			&:after {
				right: 4px;
				height: 10px;
			}
		}

		&__body {
			margin-bottom: 0;
		}
	}
}