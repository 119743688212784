/**********************************************************************/
/**********************************************************************/
.main {
    display: flex;
    flex-direction: column;
    padding-top: 150px;
    overflow-x: hidden;

    /******************************************************************/
    @include is-screen-max($breakpoint-md) {
        padding-top: 120px;
    }
}

/**********************************************************************/
/**********************************************************************/
@import "home";
@import "catalog";
@import "product";
@import "txtpage";
@import "contacts";
@import "about";
@import "cart";
@import "checkout";
@import "404";
@import "account";
@import "dealers";
@import "history";
@import "news";
@import "news-article";
@import "vacancies";
@import "vacancy";
@import "author";
