/**********************************************************************/
/**********************************************************************/
.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;

    @include is-screen-max($breakpoint-md) {
        flex-wrap: nowrap;
        margin-left: -20px;
        margin-right: -20px;
        padding: 0 20px !important;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__item {
        position: relative;
        font-size: 16px;
        line-height: 30px;
        color: #424242;

        @include is-screen-max($breakpoint-md) {
            flex-shrink: 0;
            font-size: 14px;
            line-height: 20px;
        }

        &:not(:last-child) {
            margin-right: 20px;
            padding-right: 25px;

            @include is-screen-max($breakpoint-md) {
                margin-right: 10px;
                padding-right: 15px;
            }

            &::after {
                content: "/";
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                font-size: 16px;
                line-height: 30px;
                color: #424242;

                @include is-screen-max($breakpoint-md) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        a {
            color: #787878;
            transition: color 0.3s;

            &:hover {
                color: #448a33;
            }
        }
    }
}
