/**********************************************************************/
/**********************************************************************/
.cookie-message {
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 100vw;
	max-width: inherit;
	padding: 20px 0;
	background-color: #2F2F2F;
	transition: opacity 0.3s;
	z-index: 300;

	&.hide {
		opacity: 0;
		pointer-events: none;
	}

	&__body {
		display: flex;
		justify-content: space-between;

		@include is-screen-max($breakpoint-md) {
			flex-direction: column;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		width: calc(100% - 130px);
		margin: auto 0;
		font-weight: 400;
		font-size: 13px;
		line-height: 18px;
		color: #FFFFFF;

		p {
			margin-bottom: 7px;
		}

		a {
			margin-right: auto;
			transition: opacity 0.3s;

			&:hover {
				opacity: 0.6;
			}
		}

		@include is-screen-max($breakpoint-md) {
			width: 100%;
			margin: 0;
			font-size: 10px;
			line-height: 14px;
		}
	}

	&__btn {
		min-width: auto;
		min-height: 40px;
		margin: auto 0;
		border-radius: 20px;

		.btn {
			&__name {
				padding: 0 18px;
				font-size: 12px;
				line-height: 120%;
			}
		}

		@include is-screen-max($breakpoint-md) {
			margin: 20px auto 0;
		}
	}
}