/**********************************************************************/
/**********************************************************************/
.mobmenu {
    position: fixed;
    left: 0;
    right: 0;
    top: 40px;
    bottom: 0;
    padding: 20px 0 0;
    background-color: #ffffff;
    transition: opacity 0.3s;
    opacity: 0;
    pointer-events: none;
    z-index: 200;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50vw);
        width: 100vw;
        background-color: #ffffff;
        z-index: -1;
    }

    .select {
        display: none;
    }

    &__close {
        position: absolute;
        top: 40px;
        right: 0;
        width: 24px;
        height: 24px;
        transition: opacity 0.3s;
        cursor: pointer;
        z-index: 20;

        &:hover {
            opacity: 0.6;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 25px;
            height: 3px;
            border-radius: 2px;
            background-color: #000000;
            transform-origin: center;
        }

        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    .row {
        padding-top: 60px;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__search {
        display: none;
    }

    &__list {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-left: 35px;
    }

    &__img {
        position: absolute;
        left: 0;
        top: -45px;
        width: 145px;
        z-index: -1;
    }

    &__title {
        margin-bottom: 15px;
        font-weight: 300;
        font-size: 24px;
        line-height: 110%;
        color: #226223;
        text-transform: uppercase;
    }

    .menu {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-right: auto;
        padding-top: 30px;
        padding-left: 0;
        border-top: 2px solid rgba(0, 0, 0, 0.2);
        list-style: none;

        &__item {
            display: flex;
            flex-direction: column;

            .accordion {
                &__head {
                    padding: 0;
                    border-bottom: none;

                    p {
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 24px;
                        color: #424242;
                        text-transform: initial;
                    }

                    &::before,
                    &::after {
                        left: -16px;
                        right: initial;
                        transform: translate(-50%, -50%);
                        background-color: #40b04e;
                    }

                    &::before {
                        width: 14px;
                    }

                    &::after {
                        height: 14px;
                    }
                }

                &__body {
                    margin: 15px 0 0;
                }
            }

            a {
                margin-right: auto;
                font-weight: 300;
                font-size: 18px;
                line-height: 24px;
                color: #424242;
                transition: opacity 0.3s;

                &:hover {
                    opacity: 0.6;
                }
            }

            &.active {
                a {
                    font-weight: 500;
                }
            }
        }

        &__children {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 15px;
            margin-left: 15px;
            padding-left: 0;
            list-style: none;

            a {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    &__download {
        min-height: 50px;
        margin-top: 30px;
        margin-right: auto;

        .btn {
            &__name {
                font-size: 14px;
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-md) {
        top: 0;
        padding: 0;

        &__navbar {
            position: relative;
            display: flex;
            height: 60px;
            margin: 0 -20px;
            padding: 0 60px 0 20px;
            border-bottom: 1px solid #bfbfbf;
        }

        .select {
            position: relative;
            display: block;
            margin: auto 0;
            transition: opacity 0.3s;

            select {
                position: absolute;
                width: 0;
                height: 0;
                opacity: 0;
                pointer-events: none;
            }

            &__selected {
                position: relative;
                width: 100%;
                padding: 10px 25px 10px 10px;
                background-color: #ffffff;
                font-size: 14px;
                line-height: 20px;
                color: #000000;
                transition: color 0.3s;
                cursor: pointer;

                img {
                    position: absolute;
                    right: 4px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 12px;
                    transition: transform 0.3s;
                }
            }

            &__items {
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                max-height: 200px;
                padding: 0 25px 0 10px;
                background-color: #ffffff;
                overflow-y: auto;
                z-index: 120;

                &.hide {
                    display: none;
                }
            }

            &__item {
                padding: 0 0 15px;
                font-family: "NoahText", sans-serif;
                font-size: 14px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.4);
                transition: color 0.3s;
                cursor: pointer;

                &:hover {
                    color: #000000;
                }

                &.same {
                    display: none;
                }
            }

            &.open {
                .select__selected {
                    img {
                        transform: translateY(-50%) scaleY(-1);
                    }
                }
            }
        }

        &__close {
            top: 20px;
            right: 20px;
            width: 20px;
            height: 20px;

            &:before,
            &:after {
                width: 20px;
                height: 2px;
            }
        }

        & > .row {
            width: 100vw;
            height: calc(100% - 60px);
            margin: 0 -20px;
            padding: 10px 10px 20px;

            .col {
                width: 100%;

                &:nth-child(2) {
                    margin-top: 75px;
                }
            }
        }

        &__search {
            position: relative;
            display: flex;
            width: 100%;
            max-width: 360px;
            margin-bottom: 45px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            border-radius: 20px;
            background-color: #ffffff;
            z-index: 100;
            overflow: hidden;

            img {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
            }

            input {
                width: 100%;
                padding: 0;
                padding: 10px 20px 10px 50px;
                border: none;
                background-color: #ffffff;
                font: normal 400 16px / 18px "Muller", sans-serif;
                color: #000000;
                outline: none;
                appearance: none;

                &:placeholder {
                    color: rgba(0, 0, 0, 0.3);
                }
            }
        }

        &__list {
            padding-left: 0;
        }

        &__img {
            position: absolute;
            left: -40px;
            top: -40px;
        }

        &__title {
            margin-bottom: 10px;
            font-size: 18px;
        }

        .menu {
            margin-right: 0;
            padding-top: 30px;
            border-top-width: 1px;

            &__item {
                &.accordion {
                    padding-left: 25px;

                    .accordion {
                        &__body {
                            margin-top: 10px;
                        }
                    }
                }
            }

            &__children {
                margin-bottom: 10px;
            }
        }

        &__download {
            min-height: 40px;
            margin-top: 20px;

            .btn {
                &__name {
                    font-size: 12px;
                }
            }
        }
    }
}
