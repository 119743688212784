/**********************************************************************/
/**********************************************************************/
.page-checkout {
    margin-bottom: 190px;

    &__titlearea {
        margin: 50px 0 30px;
    }

    &__title {
        margin: 20px 0 0;
        font-weight: 300;
        font-size: 36px;
        line-height: 130%;
        color: #003201;
        text-transform: uppercase;
    }

    /******************************************************************/
    .checkout {
        &__col {
            display: flex;
            flex-direction: column;
            gap: 40px;

            &-title {
                margin-bottom: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                font-size: 18px;
                line-height: 110%;
                color: #363636;
                text-transform: uppercase;
            }
        }

        .checklist {
            &__item {
                label {
                    text-transform: initial;
                }
            }
        }

        .btn {
            width: 220px;
            min-height: 50px;
            margin-top: 20px;
        }

        /**************************************************************/
        &-personal {
            margin-bottom: 40px;

            &__fields {
                margin-top: 20px;
            }
        }

        /**************************************************************/
        &-delivery {
            &__fields {
                margin-top: 40px;
            }

            &__self {
                margin-top: 20px;
                font: normal 300 16px / 120% "Muller", sans-serif;
                color: #000000;
            }
        }

        /**************************************************************/
        &-bonus {
            width: 450px !important;
            max-width: 50%;
            margin-top: 50px;
            margin-bottom: 40px;

            &__login {
                margin-top: 10px;
            }

            &__txt {
                font: normal 300 16px / 120% "Muller", sans-serif;
                color: #000000;
            }

            /**********************************************************/
            .bonus-code {
                margin-top: 25px;

                &__title {
                    margin-bottom: 10px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #787878;
                    text-transform: uppercase;
                }

                &__row {
                    display: flex;
                    justify-content: space-between;
                }

                &__points {
                    display: flex;

                    input {
                        width: 80px;
                        height: 50px;
                        padding: 0 10px;
                        border: 1px solid #c4c4c4;
                        border-right: none;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                        font: normal 400 24px / 50px "Muller", sans-serif;
                        color: #448a33;
                        text-transform: uppercase;
                        text-align: center;
                        outline: none;

                        &::placeholder {
                            color: rgba(0, 0, 0, 0.2);
                        }
                    }

                    button {
                        width: 170px;
                        height: 50px;
                        border: none;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                        background-color: #226223;
                        font: normal 400 12px / 50px "Muller", sans-serif;
                        color: #ffffff;
                        text-transform: uppercase;
                        text-align: center;
                        transition: background-color 0.3s;
                        cursor: pointer;

                        &:hover {
                            background-color: #199629;
                        }
                    }
                }

                &__note {
                    width: calc(100% - 270px);

                    &-value {
                        margin: 15px auto 0 0;
                        font-size: 24px;
                        line-height: 24px;
                        color: #448a33;

                        span {
                            font-size: 20px;
                        }
                    }

                    &-txt {
                        margin-left: 10px;
                        font-size: 12px;
                        line-height: 120%;
                        color: #448a33;
                    }
                }
            }
        }

        /**************************************************************/
        &-result {
            &__wrap {
                display: flex;
                flex-direction: column;
                margin-bottom: auto;
                padding: 40px 0;
                border-radius: 4px;
                background-color: #f7f7f7;
            }

            &__content {
                width: 460px;
                max-width: 100%;
                margin: 0 auto;
            }

            &__table {
                &-row {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    p {
                        margin: auto 0;
                        font-size: 14px;
                        line-height: 110%;
                        color: #363636;

                        &:nth-child(2) {
                            padding-left: 20px;
                            color: #868686;
                        }

                        &.green {
                            color: #448a33;
                        }
                    }
                }

                &-final {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    padding-top: 20px;
                    border-top: 1px solid rgba(0, 0, 0, 0.2);

                    p {
                        margin: auto 0;
                        font-size: 14px;
                        line-height: 110%;
                        color: #363636;

                        &:nth-child(2) {
                            padding-left: 20px;
                            font-size: 24px;
                            color: #448a33;
                        }
                    }
                }
            }

            &__submit {
                display: block;
                width: 100%;
                height: 50px;
                max-width: 100%;
                margin-top: 20px;
                border: 4px solid transparent;
                border-radius: 25px;
                background-color: #226223;
                font: normal 700 14px / 42px "Muller", sans-serif;
                color: #ffffff;
                text-transform: uppercase;
                text-align: center;
                cursor: pointer;
                transition: border-color 0.3s;

                &:hover {
                    border-color: #199629;
                }
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-lg) {
        /**************************************************************/
        .checkout {
            width: 750px;
            max-width: 100%;
            margin: 0 auto;

            /**********************************************************/
            &__col {
                width: 100% !important;
                max-width: 100%;
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-md) {
        margin-bottom: 60px;

        &__titlearea {
            margin: 20px 0;
        }

        &__title {
            font-size: 24px;
        }

        /**************************************************************/
        .checkout {
            &__col {
                gap: 20px;

                &-title {
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                }
            }

            .checklist {
                &.inline {
                    flex-direction: column;

                    .checklist__item {
                        margin-right: 0;
                    }
                }
            }

            .btn {
                min-height: 40px;
                border-radius: 20px;
            }

            /**********************************************************/
            &-personal {
                margin-bottom: 30px;

                &__fields {
                    margin-top: 20px;
                }
            }

            /**********************************************************/
            &-delivery {
                margin-bottom: 30px;

                &__fields {
                    margin-top: 10px;
                }

                &__self {
                    margin-top: 10px;
                }
            }

            /**********************************************************/
            &-bonus {
                margin-bottom: 30px;

                &__txt {
                    font-size: 14px;
                }

                /******************************************************/
                .bonus-code {
                    margin-top: 10px;

                    &__row {
                        flex-direction: column;
                    }

                    &__note {
                        width: 100%;

                        &-value {
                            margin-top: 25px;
                        }
                    }
                }
            }

            /**********************************************************/
            &-result {
                &__wrap {
                    width: calc(100% + 40px);
                    margin: 0 -20px 0;
                    padding: 20px;
                    border-radius: 0;
                }

                &__table {
                    &-row {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 20px;

                        p {
                            margin: auto 0;
                            font-size: 14px;
                            line-height: 110%;
                            color: #363636;

                            &:nth-child(2) {
                                padding-left: 20px;
                                color: #868686;
                            }

                            &.green {
                                color: #448a33;
                            }
                        }
                    }

                    &-final {
                        display: flex;
                        justify-content: space-between;
                        padding-top: 20px;
                        border-top: 1px solid rgba(0, 0, 0, 0.2);

                        p {
                            margin: auto 0;
                            font-size: 14px;
                            line-height: 110%;
                            color: #363636;

                            &:nth-child(2) {
                                padding-left: 20px;
                                font-size: 24px;
                                color: #448a33;
                            }
                        }
                    }
                }

                &__submit {
                    display: block;
                    width: 100%;
                    height: 50px;
                    max-width: 100%;
                    border: 4px solid transparent;
                    border-radius: 25px;
                    background-color: #226223;
                    font: normal 700 14px / 42px "Muller", sans-serif;
                    color: #ffffff;
                    text-transform: uppercase;
                    text-align: center;
                    cursor: pointer;
                    transition: border-color 0.3s;

                    &:hover {
                        border-color: #199629;
                    }
                }
            }
        }
    }
}
