.select {
    position: relative;
    display: block;
    margin: auto 0;
    transition: opacity 0.3s;

    select {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        pointer-events: none;
    }

    &__selected {
        position: relative;
        width: 100%;
        padding: 15px 25px 15px 15px;
        background-color: #FFFFFF;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        transition: color 0.3s;
        cursor: pointer;

        @include is-screen-max($breakpoint-md) {
            padding: 10px 25px 10px 10px;
        }

        img {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 9px;
            transition: transform 0.3s;

            @include is-screen-max($breakpoint-md) {
                right: 4px;
                width: 12px;
            }
        }
    }

    &__items {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        max-height: 200px;
        padding: 0 25px 0 15px;
        background-color: #FFFFFF;
        overflow-y: auto;
        z-index: 120;

        &.hide {
            display: none;
        }
    }

    &__item {
        padding: 0 0 15px;
        font-family: "NoahText", sans-serif;
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.4);
        transition: color 0.3s;
        cursor: pointer;

        &:hover {
            color: #000000;
        }

        &.same {
            display: none;
        }
    }

    &.open {
        .select__selected {
            img {
                transform: translateY(-50%) scaleY(-1);
            }
        }
    }
}