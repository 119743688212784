.author-profile {
    display: flex;
    align-items: flex-start;
    gap: 40px;

    @include is-screen-max($breakpoint-md) {
        flex-direction: column;
        gap: 20px;
        width: 450px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    &__image {
        flex-shrink: 0;
        width: 30%;
        border-radius: 10px;

        @include is-screen-max($breakpoint-md) {
            width: 100%;
        }
    }

    .author-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        width: calc(70% - 40px);

        @include is-screen-max($breakpoint-md) {
            gap: 15px;
            width: 100%;
        }

        &__name {
            font-weight: 300;
            font-size: 36px;
            line-height: 130%;
            color: #448a33;
            text-transform: uppercase;

            @include is-screen-max($breakpoint-md) {
                font-size: 28px;
            }
        }

        &__position {
            display: flex;
            flex-direction: column;
            gap: 10px;

            @include is-screen-max($breakpoint-md) {
                gap: 5px;
            }

            p {
                font-size: 20px;
                line-height: 25px;
                color: #868686;
                text-transform: uppercase;

                @include is-screen-max($breakpoint-md) {
                    font-size: 16px;
                    line-height: 20px;
                }

                &:first-child {
                    color: #000000;
                }
            }
        }

        &__description {
            width: 540px;
            max-width: 100%;
            font-size: 20px;
            line-height: 150%;
            color: #363636;

            @include is-screen-max($breakpoint-md) {
                font-size: 16px;
            }

            ul,
            ol {
                padding-left: 30px;

                @include is-screen-max($breakpoint-md) {
                    padding-left: 20px;
                }
            }
        }
    }
}
