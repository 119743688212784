/**********************************************************************/
/**********************************************************************/
.page-txt {
    margin-bottom: 190px;

    &__titlearea {
        width: calc(100% - 290px);
        margin: 50px 0 20px auto;
    }

    &__title {
        width: 650px;
        max-width: 100%;
        margin: 20px auto 0 0;
        font-weight: 300;
        font-size: 36px;
        line-height: 130%;
        color: #003201;
        text-transform: uppercase;
    }

    /******************************************************************/
    &__body {
        display: flex;
        justify-content: space-between;
    }

    /******************************************************************/
    &__content {
        position: relative;
        width: calc(100% - 290px);
        padding-top: 30px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 10px 20px;
        margin-top: 40px;

        .txt-button {
            display: flex;
            align-items: center;
            gap: 16px;

            &__button {
                position: relative;
                flex-shrink: 0;
                min-height: 50px !important;
                min-width: 0 !important;
                margin-right: 0;
                border-radius: 25px !important;
                text-decoration: none;
                transition: background-color 0.3s, border-color 0.3s;

                .btn {
                    &__name {
                        padding: 3px 60px 0;
                        font-size: 12px;
                    }
                }

                img {
                    position: absolute;
                    right: 27px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 16px;
                }

                &:hover {
                    background-color: #199629;
                    opacity: 1;

                    .btn {
                        &__name {
                            color: #ffffff;
                        }
                    }
                }
            }

            &__note {
                max-width: 160px;
                font-weight: 400;
                font-size: 14px;
                line-height: 120%;
                color: #787878;
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-lg) {
        &__titlearea {
            width: 100%;
        }

        &__title {
            width: 100%;
        }

        /**************************************************************/
        &__body {
            flex-direction: column;
        }

        /*************************************************************/
        &__content {
            width: 100%;

            &:before {
                width: 100%;
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-md) {
        margin-bottom: 60px;

        &__titlearea {
            margin-top: 20px;
        }

        &__title {
            font-size: 24px;
        }

        &__buttons {
            gap: 20px;
            margin-top: 30px;

            .txt-button {
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;

                &__button {
                    min-height: 40px !important;

                    .btn {
                        &__name {
                            padding: 3px 30px 0;
                        }
                    }

                    img {
                        display: none;
                    }
                }

                &__note {
                    max-width: 400px;
                    font-size: 12px;
                }
            }
        }
    }
}
