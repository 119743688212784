.slider {
    position: relative;

    * {
        outline: none;
    }

    &__arr {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        width: 30px;
        height: 100%;
        transition: opacity 0.3s;
        cursor: pointer;

        @include is-screen-max($breakpoint-xl) {
            width: 20px;
        }

        &_l {
            left: -40px;

            @include is-screen-max($breakpoint-xl) {
                left: -20px;
            }
        }

        &_r {
            right: -40px;

            @include is-screen-max($breakpoint-xl) {
                right: -20px;
            }
        }

        &:hover {
            opacity: 0.6;
        }

        img {
            width: 12px;
            margin: auto;

            @include is-screen-max($breakpoint-xl) {
                width: 10px;
            }
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 15px;
        display: flex;
        justify-content: center;

        li {
            width: 5px;
            height: 5px;
            margin: 0 2px;
            border-radius: 50%;
            background-color: #c4c4c4;
            transition: background-color 0.3s;
            cursor: pointer;

            &.slick-active,
            &:hover {
                background-color: #868686;
            }

            button {
                display: none;
            }
        }
    }

    &-nav {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 30px;
        margin-top: 30px;
        margin-bottom: 40px;

        @include is-screen-max($breakpoint-lg) {
            column-gap: 20px;
            margin-top: 20px;
        }

        @include is-screen-max($breakpoint-md) {
            column-gap: 10px;
            margin-bottom: 30px;
        }

        &__item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 1 0;
            height: 130px;
            max-width: 146px;
            min-width: 100px;

            @include is-screen-max($breakpoint-lg) {
                max-width: 120px;
                min-width: 80px;
                height: 80px;
            }

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}
