/**********************************************************************/
/**********************************************************************/
$breakpoint-xs: 360px;
$breakpoint-sm: 480px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1180px;
$breakpoint-xxl: 1560px;

@mixin is-screen-max($size) {
    @media screen and (max-width: $size) {
        @content;
    }
}

@mixin is-screen-min($size) {
    @media screen and (min-width: $size - 1) {
        @content;
    }
}

/**********************************************************************/
/**********************************************************************/
body {
    position: relative;
    margin: 0;
    font: normal 400 13px / 18px "Muller", sans-serif;
    color: #000000;
    cursor: default;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;

    * {
        margin: 0;
        box-sizing: border-box;
    }

    a {
        display: inline-block;
        text-decoration: none;
        color: inherit;
    }

    img {
        display: block;
        max-width: 100%;
    }

    strong,
    b {
        font-weight: 700;
    }

    i {
        font-style: italic;
    }

    .hide {
        display: none !important;
    }

    /******************************************************************/
    .container {
        display: flex;
        flex-direction: column;

        &__content {
            width: 1120px;
            max-width: calc(100% - 40px);
            margin-left: auto;
            margin-right: auto;
        }
    }

    .row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-right: -10px;

        .col {
            display: flex;
            flex-direction: column;
            padding-left: 10px;
            padding-right: 10px;

            @for $i from 1 through 12 {
                &-#{$i} {
                    width: calc($i * 100% / 12);
                }
            }

            @include is-screen-max($breakpoint-xl) {
                &-xl {
                    @for $i from 1 through 12 {
                        &-#{$i} {
                            width: calc($i * 100% / 12);
                        }
                    }
                }
            }

            @include is-screen-max($breakpoint-lg) {
                &-lg {
                    @for $i from 1 through 12 {
                        &-#{$i} {
                            width: calc($i * 100% / 12);
                        }
                    }
                }
            }

            @include is-screen-max($breakpoint-md) {
                &-md {
                    @for $i from 1 through 12 {
                        &-#{$i} {
                            width: calc($i * 100% / 12);
                        }
                    }
                }
            }

            @include is-screen-max($breakpoint-sm) {
                &-sm {
                    @for $i from 1 through 12 {
                        &-#{$i} {
                            width: calc($i * 100% / 12);
                        }
                    }
                }
            }
        }
    }

    /******************************************************************/
    &.mobmenu-on {
        overflow: hidden;

        .mobmenu {
            opacity: 1;
            pointer-events: initial;
        }
    }

    /******************************************************************/
    &.popup-on {
        overflow: hidden;

        .overlay {
            opacity: 1;
            pointer-events: initial;
        }
    }
}

/**********************************************************************/
/**********************************************************************/
@import "fonts";

@import "elements/index";
@import "formarea/index";

@import "header/index";
@import "footer/index";

@import "pages/index";
