.news-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    &__content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;

        @include is-screen-max($breakpoint-lg) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include is-screen-max($breakpoint-md) {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
}
