.page-home {
    .topbanner {
        position: relative;
        min-height: 545px;
        margin-bottom: 190px;

        @include is-screen-max($breakpoint-md) {
            min-height: 420px;
            margin-bottom: 60px;
        }

        &__body {
            display: flex;
            flex-direction: column;
            width: 600px;
            max-width: 100%;
            margin-top: 80px;

            @include is-screen-max($breakpoint-md) {
                flex-direction: row;
                justify-content: space-between;
                width: 400px;
                max-width: 100%;
                padding-top: 45px;
                margin-top: 0;
                margin-left: auto;
                margin-right: auto;
            }
        }

        &__col {
            display: flex;
            flex-direction: column;

            &:nth-child(1) {
                @include is-screen-max($breakpoint-md) {
                    width: 180px;
                }

                @include is-screen-max($breakpoint-xs) {
                    width: 160px;
                }
            }

            &:nth-child(2) {
                @include is-screen-max($breakpoint-md) {
                    width: 150px;
                    max-width: calc(100% - 200px);
                }

                @include is-screen-max($breakpoint-xs) {
                    max-width: calc(100% - 180px);
                }
            }
        }

        &__title {
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 50px;
            line-height: 100%;
            letter-spacing: 0.05em;
            color: #003201;
            text-transform: uppercase;

            @include is-screen-max($breakpoint-md) {
                margin-bottom: auto;
                padding-bottom: 10px;
                font-size: 30px;
                line-height: 120%;
            }

            @include is-screen-max($breakpoint-xs) {
                font-size: 28px;
                letter-spacing: 0;
            }
        }

        &__subtitle {
            margin-bottom: 25px;
            font-weight: 300;
            font-size: 40px;
            line-height: 110%;
            color: #003201;
            text-transform: uppercase;

            @include is-screen-max($breakpoint-md) {
                margin-bottom: -5px;
                font-size: 24px;
                line-height: 120%;
            }

            @include is-screen-max($breakpoint-xs) {
                font-size: 20px;
            }
        }

        &__txt {
            width: 420px;
            max-width: 100%;
            margin-bottom: 35px;
            font-size: 24px;
            line-height: 120%;
            color: #003201;
            text-transform: uppercase;

            @include is-screen-max($breakpoint-md) {
                width: 100%;
                margin-bottom: 15px;
                font-size: 16px;
            }

            @include is-screen-max($breakpoint-xs) {
                font-size: 13px;
            }
        }

        &__btn {
            @include is-screen-max($breakpoint-xs) {
                min-height: 35px;
                min-width: 100px;

                .btn__name {
                    padding-top: 2px;
                    font-size: 11px;
                }
            }
        }

        &__catalog {
            position: absolute;
            top: 10px;
            right: 180px;
            width: 220px;

            @include is-screen-max($breakpoint-xl) {
                right: 100px;
            }

            @include is-screen-max($breakpoint-lg) {
                display: none;
            }

            &-mob {
                display: none;
                margin-top: 20px;

                @include is-screen-max($breakpoint-lg) {
                    display: flex;
                }

                @include is-screen-max($breakpoint-md) {
                    width: 120px;
                    margin-top: 10px;
                }

                @include is-screen-max($breakpoint-xs) {
                    width: 100px;
                    min-width: 0;

                    .btn__name {
                        font-size: 11px;
                    }
                }
            }
        }

        &__img {
            position: absolute;
            bottom: -50px;
            left: 50%;
            transform: translateX(-50%);
            width: 1920px;
            height: auto;
            max-width: initial;
            z-index: -1;

            @include is-screen-max($breakpoint-lg) {
                left: 65%;
            }

            @include is-screen-max($breakpoint-md) {
                bottom: -20px;
                left: 50%;
                width: 1000px;
            }
        }
    }

    .home-titlearea {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 580px;
        max-width: 100%;
        padding: 0 30px;
        margin: 0 auto 50px;

        @include is-screen-max($breakpoint-lg) {
            display: flex;
            flex-direction: column;
            padding: 0;
        }

        @include is-screen-max($breakpoint-md) {
            margin-bottom: 40px;
        }

        &__title {
            min-height: 135px;
            font-weight: 300;
            font-size: 40px;
            line-height: 45px;
            color: #003201;
            text-align: center;
            text-transform: uppercase;

            @include is-screen-max($breakpoint-lg) {
                min-height: 0;
            }

            @include is-screen-max($breakpoint-md) {
                font-size: 22px;
                line-height: 26px;
            }
        }

        &__btn {
            position: absolute;
            top: 15px;
            right: 0;
            transform: translateX(100%);

            @include is-screen-max($breakpoint-lg) {
                position: relative;
                top: initial;
                right: initial;
                transform: none;
                margin: 30px auto 0;
            }
        }
    }

    .news-slider {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 235px;

        @include is-screen-max($breakpoint-md) {
            margin-bottom: 105px;
        }

        .home-titlearea {
            &__title {
                min-height: 0;
            }

            &__btn {
                @include is-screen-min($breakpoint-lg) {
                    top: 50%;
                    transform: translate(100%, -50%);
                }
            }
        }

        .slider {
            width: calc(100% + 24px);
            max-width: calc(100vw - 80px);
            margin: 0 -12px;

            @include is-screen-max($breakpoint-xl) {
                width: calc(100% + 8px);
                max-width: calc(100vw - 40px);
                margin: 0 -4px;
            }

            &__arr {
                width: 20px;

                &_l {
                    left: -28px;

                    @include is-screen-max($breakpoint-xl) {
                        left: -20px;
                    }
                }

                &_r {
                    right: -28px;

                    @include is-screen-max($breakpoint-xl) {
                        right: -20px;
                    }
                }

                img {
                    width: 20px;

                    @include is-screen-max($breakpoint-xl) {
                        width: 12px;
                    }
                }
            }

            .slick-dots {
                bottom: -30px;
                transform: translateY(100%);
                align-items: center;
                gap: 12px;

                li {
                    width: 12px;
                    height: 12px;
                    margin: 0;
                    background-color: #dedede;

                    &:hover {
                        background-color: #c4c4c4;
                    }

                    &.slick-active {
                        width: 14px;
                        height: 14px;
                        background-color: #c4c4c4;
                    }
                }
            }

            .news-card {
                margin: 0 12px;

                @include is-screen-max($breakpoint-xl) {
                    margin: 0 4px;
                }
            }
        }
    }

    .products {
        margin-bottom: 190px;

        @include is-screen-max($breakpoint-md) {
            margin-bottom: 60px;
        }
    }

    .steps {
        position: relative;
        min-height: 650px;
        margin-bottom: 190px;

        @include is-screen-max($breakpoint-md) {
            min-height: 0;
            margin-bottom: 60px;
        }

        &__title {
            width: 600px;
            max-width: 100%;
            margin-bottom: 60px;
            font-weight: 300;
            font-size: 40px;
            line-height: 110%;
            color: #003201;
            text-transform: uppercase;

            @include is-screen-max($breakpoint-md) {
                width: 100%;
                margin-bottom: 20px;
                font-size: 22px;
                line-height: 120%;
                text-align: center;
            }
        }

        &__items {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 520px;
            max-width: 100%;
            z-index: 100;

            @include is-screen-max($breakpoint-md) {
                flex-direction: column;
                margin: 0 auto;
                padding-bottom: 260px;
            }

            .step {
                position: relative;
                display: flex;
                flex-direction: column;
                width: calc(50% - 10px);
                margin-bottom: 35px;

                @include is-screen-max($breakpoint-md) {
                    width: 170px;
                    margin: 0 auto -10px;
                }

                @include is-screen-max($breakpoint-md) {
                    &:nth-child(2n) {
                        transform: translateX(-20px);
                    }

                    &:nth-child(2n + 1) {
                        transform: translateX(60px);
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                img {
                    position: absolute;
                    left: -35px;
                    top: 60px;
                    width: 165px;
                    z-index: -1;

                    @include is-screen-max($breakpoint-md) {
                        left: -20px;
                        top: 40px;
                        width: 140px;
                    }
                }

                &__num {
                    font-weight: 100;
                    font-size: 144px;
                    line-height: 110%;

                    @include is-screen-max($breakpoint-md) {
                        font-size: 130px;
                    }
                }

                &:nth-child(1) .step__num {
                    color: #e8988d;
                }
                &:nth-child(2) .step__num {
                    color: #98ca8c;
                }
                &:nth-child(3) .step__num {
                    color: #9488c6;
                }
                &:nth-child(4) .step__num {
                    color: #7998cc;
                }

                &__txt {
                    width: 230px;
                    min-height: 70px;
                    margin: -15px 0 auto 35px;
                    font-weight: 300;
                    font-size: 20px;
                    line-height: 110%;
                    color: #363636;
                    text-transform: uppercase;

                    @include is-screen-max($breakpoint-md) {
                        width: 100%;
                        min-height: 0;
                        margin: -10px 0 auto 0;
                        font-size: 16px;
                    }
                }
            }
        }

        &__bg {
            position: absolute;
            bottom: 0;
            left: calc(50% + 50vw);
            transform: translateX(-100%);
            width: 1200px;
            z-index: -1;

            @include is-screen-min($breakpoint-xxl) {
                left: initial;
                right: 0;
                transform: none;
            }

            @include is-screen-max($breakpoint-md) {
                left: initial;
                right: 0;
                transform: translate(150px, 30px);
                width: 800px;
                max-width: 800px;
            }
        }
    }
}
