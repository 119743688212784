/**********************************************************************/
/**********************************************************************/
.checklist {
    display: flex;
    flex-direction: column;

    &__item {
        label {
            position: relative;
            display: inline-block;
            width: 100%;
            padding: 10px 0 10px 30px;
            font-size: 14px;
            line-height: 16px;
            color: #787878;
            text-transform: uppercase;
            cursor: pointer;

            &.is-lower {
                text-transform: initial;
            }

            a {
                text-decoration: underline;
                text-decoration-thickness: 1px;
                text-underline-offset: 3px;
                transition: opacity 0.3s;

                &:hover {
                    opacity: 0.6;
                }
            }

            span {
                position: absolute;
                top: 10px;
                left: 0;
                width: 16px;
                height: 16px;
                border: 1px solid #bfbfbf;
                border-radius: 50%;
                background-color: #ffffff;
                transition: border-color 0.3s, background-color 0.3s;

                &:before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: #787878;
                    opacity: 0;
                    transition: opacity 0.3s;
                }
            }

            input {
                width: 0;
                height: 0;
                margin: 0;
                padding: 0;
                opacity: 0;
                pointer-events: none;

                &:checked ~ span {
                    &:before {
                        opacity: 1;
                    }
                }

                &.error {
                    & ~ label {
                        &.error {
                            & ~ span {
                                border-color: #f5362d;
                            }
                        }
                    }
                }
            }

            &:hover {
                span {
                    border-color: #787878;
                }
            }

            &.error {
                color: #f5362d;
            }
        }
    }

    &.inline {
        flex-direction: row;

        .checklist__item {
            margin-right: 40px;
        }
    }
}
