/**********************************************************************/
/**********************************************************************/
.btn {
    display: flex;
    flex-direction: column;
    min-height: 60px;
    min-width: 170px;
    max-width: 100%;
    margin-right: auto;
    border: 4px solid transparent;
    border-radius: 30px;
    background-color: #ffffff;
    transition: border-width 0.1s, border-color 0.3s;
    cursor: pointer;

    &__name {
        margin: auto;
        padding: 3px 20px 0;
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
    }

    /******************************************************************/
    &-bordered {
        border-width: 2px;
        border-color: #226223;
        transition: border-color 0.3s;

        &:hover {
            border-color: #199629;
        }

        .btn__name {
            color: #226223;
        }
    }

    &-green {
        background-color: #226223;

        &:hover {
            border-color: #199629;
        }
    }

    &-brown {
        background-color: #c3aa95;

        &:hover {
            border-color: #a8907b;
        }
    }

    &-light-green {
        background-color: #76c390;

        &:hover {
            border-color: #58af75;
        }
    }

    &-blue {
        background-color: #7998cc;

        &:hover {
            border-color: #5d7fb9;
        }
    }

    &-dark-grey {
        background-color: #686b6c;

        &:hover {
            border-color: #505759;
        }
    }

    &-light-grey {
        background-color: #c4c4c4;

        &:hover {
            border-color: #afafaf;
        }
    }

    &-black {
        background-color: #353535;

        &:hover {
            border-color: #000000;
        }
    }

    /******************************************************************/
    &-sm {
        min-height: 30px !important;
        min-width: 80px !important;
        border-radius: 15px !important;
        border-width: 2px;

        .btn__name {
            padding: 3px 10px 0;
            font-size: 12px;
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-md) {
        min-height: 40px;
        min-width: 120px;
        border-radius: 20px;

        &__name {
            padding: 3px 10px 0;
            font-size: 12px;
        }
    }
}
