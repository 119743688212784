.page-article {
    width: 915px;
    max-width: 100%;
    margin: 50px auto 110px;

    @include is-screen-max($breakpoint-md) {
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .breadcrumbs {
        margin-bottom: 30px;

        @include is-screen-max($breakpoint-md) {
            margin-bottom: 20px;
        }
    }

    .article-meta {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 20px;
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        @include is-screen-max($breakpoint-md) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 30px;
            padding-bottom: 20px;
        }

        .meta-author {
            display: flex;
            align-items: flex-end;
            gap: 20px;

            @include is-screen-max($breakpoint-md) {
                gap: 14px;
            }

            &__image {
                width: 96px;
                height: 96px;
                border-radius: 10px;
                overflow: hidden;

                @include is-screen-max($breakpoint-md) {
                    width: 80px;
                    height: 80px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    max-width: initial;
                    max-height: initial;
                    object-fit: cover;
                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                gap: 15px;
            }

            &__name {
                font-size: 20px;
                line-height: 100%;
                color: #363636;

                @include is-screen-max($breakpoint-md) {
                }
            }

            &__position {
                font-size: 16px;
                line-height: 100%;
                color: #363636;
            }
        }

        .meta-date {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            gap: 15px;

            @include is-screen-max($breakpoint-md) {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }

            &__item {
                display: flex;
                align-items: flex-end;
                gap: 8px;
            }

            &__icon {
                height: 24px;
            }

            &__value {
                font-weight: 500;
                font-size: 16px;
                line-height: 100%;
                color: #363636;
            }

            &__label {
                padding-left: 2px;
                font-size: 16px;
                line-height: 100%;
                color: #868686;

                @include is-screen-max($breakpoint-md) {
                    display: none;
                }
            }
        }
    }

    .article-head {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-bottom: 50px;

        @include is-screen-max($breakpoint-md) {
            gap: 20px;
            margin-bottom: 40px;
        }

        &__title {
            font-weight: 300;
            font-size: 36px;
            line-height: 130%;
            color: #448a33;
            text-transform: uppercase;

            @include is-screen-max($breakpoint-md) {
                font-size: 24px;
            }
        }

        &__subtitle {
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #363636;
        }
    }

    &__slider {
        padding: 0 45px;
        margin-bottom: 50px;

        @include is-screen-max($breakpoint-md) {
            padding: 0 0 32px;
            margin-bottom: 40px !important;
        }

        .slider {
            &__item {
                border-radius: 10px;
                overflow: hidden;
            }
        }

        .slick-dots {
            bottom: 0;
            gap: 10px;

            li {
                width: 12px;
                height: 12px;
                margin: 0;
                border: 1px solid #363636;
                background-color: transparent;
                opacity: 0.8;

                &.slick-active,
                &:hover {
                    background-color: #363636;
                }
            }
        }
    }

    &__body {
        margin-bottom: 40px;
    }

    .article-footer {
        display: flex;
        flex-direction: column;
        gap: 50px;

        @include is-screen-max($breakpoint-md) {
            gap: 40px;
        }

        .article-nav {
            position: relative;
            display: flex;
            align-items: flex-end;
            gap: 32px;

            @include is-screen-max($breakpoint-md) {
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
            }

            &__button {
                display: flex;
                align-items: flex-end;
                gap: 8px;
                transition: opacity 0.3s;
                cursor: pointer;

                @include is-screen-max($breakpoint-md) {
                }

                &:hover {
                    opacity: 0.6;
                }

                &:last-child {
                    gap: 5px;
                    margin-left: auto;

                    @include is-screen-max($breakpoint-md) {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }

                img {
                    height: 24px;
                }

                p {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 100%;
                    color: #868686;
                }
            }

            &__socials {
                display: flex;
                align-items: center;
                gap: 10px;

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 20px;

                    svg {
                        path {
                            transition: fill 0.3s;
                        }
                    }

                    &:hover {
                        svg {
                            path {
                                fill: #40b04e;
                            }
                        }
                    }
                }
            }
        }

        &__back {
            min-height: 50px;

            @include is-screen-max($breakpoint-md) {
                width: 100%;
                border-radius: 30px;
            }

            .btn {
                &__name {
                    padding: 3px 63px 0;
                    font-size: 12px;
                }
            }
        }
    }
}
