.page-vacancies {
    width: 915px;
    max-width: 100%;
    margin: 0 auto 110px;

    @include is-screen-max($breakpoint-md) {
        margin-bottom: 50px;
    }

    &__titlearea {
        width: 100%;
        margin-top: 50px;
        margin-bottom: 40px;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        @include is-screen-max($breakpoint-md) {
            margin-top: 20px;
            margin-bottom: 35px;
            padding-bottom: 20px;
        }

        .breadcrumbs {
            margin-bottom: 20px;
        }
    }

    &__title {
        font-weight: 300;
        font-size: 36px;
        line-height: 100%;
        color: #003201;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-md) {
            font-size: 24px;
        }
    }

    .vacancies-intro {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 40px;

        @include is-screen-max($breakpoint-md) {
            margin-bottom: 30px;
        }

        &__title {
            font-weight: 300;
            font-size: 24px;
            line-height: 36px;
            color: #448a33;
            text-transform: uppercase;

            @include is-screen-max($breakpoint-md) {
                font-size: 18px;
                line-height: 24px;
            }
        }

        &__subtitle {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #448a33;

            @include is-screen-max($breakpoint-md) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        width: 100%;

        .vacancy {
            &:not(:last-child) {
                border-bottom: none;
            }
        }
    }
}
