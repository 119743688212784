/**********************************************************************/
/**********************************************************************/
.navbar {
    position: relative;
    display: flex;
    align-items: center;
    height: 110px;
    background-color: #ffffff;
    transition: height 0.3s;

    &.min {
        height: 80px;

        .navbar-account {
            &__area {
                top: calc(100% + 25px) !important;

                /******************************************************/
                @include is-screen-max($breakpoint-lg) {
                    top: calc(100% + 13px) !important;
                }
            }
        }
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50vw);
        width: 100vw;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.2);
    }

    &__logo {
        margin: auto auto auto 0;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.6;
        }

        img {
            width: 200px;
        }
    }

    &__country {
        margin-right: 20px;
    }

    .iconbar {
        display: flex;
        margin: auto 20px auto 0;

        &__item {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 30px;
            height: 30px;
            margin: auto 5px;
            transition: opacity 0.3s;
            cursor: pointer;

            &:hover {
                opacity: 0.6;
            }

            img {
                width: 20px;
                margin: auto;
            }
        }

        .navbar-search {
            &__form {
                position: absolute;
                right: 0;
                top: -10px;
                bottom: -10px;
                display: flex;
                width: 360px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 25px;
                background-color: #ffffff;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.3s;
                z-index: 100;
                overflow: hidden;

                /******************************************************/
                @include is-screen-max($breakpoint-lg) {
                    width: 300px;
                }

                img {
                    position: absolute;
                    left: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 20px;
                }

                input {
                    width: 100%;
                    padding: 0;
                    padding: 10px 40px 10px 50px;
                    border: none;
                    background-color: #ffffff;
                    font: normal 400 16px / 28px "Muller", sans-serif;
                    color: #000000;
                    outline: none;
                    appearance: none;

                    &:placeholder {
                        color: rgba(0, 0, 0, 0.3);
                    }
                }
            }

            &__close {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
                height: 20px;
                transition: opacity 0.3s;
                cursor: pointer;

                &:hover {
                    opacity: 0.6;
                }

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 18px;
                    height: 1px;
                    background-color: #323232;
                    transform-origin: center;
                }

                &:before {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }

                &:after {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }

            &.open {
                opacity: 1;
                cursor: default;

                .navbar-search__form {
                    opacity: 1;
                    pointer-events: initial;
                }
            }
        }

        .navbar-cart {
            &__counter {
                position: absolute;
                right: -5px;
                top: -5px;
                width: 20px;
                height: 20px;
                padding-top: 2px;
                border-radius: 50%;
                background-color: #226223;
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
                color: #ffffff;
                text-align: center;
            }
        }

        .navbar-account {
            &__area {
                position: absolute;
                left: 50%;
                top: calc(100% + 40px);
                transform: translateX(-50%);
                display: flex;
                flex-direction: column;
                width: 260px;
                padding: 30px 0 20px;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
                background-color: #ffffff;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.3s, top 0.3s;
                z-index: 150;

                /******************************************************/
                @include is-screen-max($breakpoint-lg) {
                    left: 100%;
                    top: calc(100% + 20px);
                    transform: translateX(-100%);
                }
            }

            &__close {
                position: absolute;
                right: 15px;
                top: 15px;
                width: 20px;
                height: 20px;
                transition: opacity 0.3s;
                cursor: pointer;

                &:hover {
                    opacity: 0.6;
                }

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 18px;
                    height: 1px;
                    background-color: #323232;
                    transform-origin: center;
                }

                &:before {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }

                &:after {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }

            &.open {
                opacity: 1;
                cursor: default;

                .navbar-account__area {
                    opacity: 1;
                    pointer-events: initial;
                }
            }

            &__title {
                margin: 0 auto 10px;
                font-size: 20px;
                line-height: 100%;
                color: #363636;
                text-transform: uppercase;
                text-align: center;
            }

            &__name {
                margin: 0 auto 20px;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
                text-align: center;
            }

            .account-list {
                display: flex;
                flex-direction: column;

                &__icon {
                    position: absolute;
                    left: 25px;
                    top: 50%;
                    transform: translateY(-50%);
                    max-width: 20px;
                    max-height: 20px;
                }

                &__name {
                    font-size: 14px;
                    line-height: 20px;
                    color: #787878;
                    text-transform: uppercase;
                    transition: color 0.3s;
                }

                &__counter {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    width: 45px;

                    img {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        z-index: -1;
                    }

                    span {
                        font-size: 20px;
                        line-height: 100%;
                        color: #ffffff;
                        text-transform: uppercase;
                        text-align: center;
                    }
                }

                &__item {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 25px 0 60px;
                    margin-bottom: 20px;

                    &:nth-child(1),
                    &:nth-child(4) {
                        margin-bottom: 41px;

                        &:after {
                            content: "";
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: -20px;
                            height: 1px;
                            background-color: #bfbfbf;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:hover {
                        .account-list {
                            &__name {
                                color: #448a33;
                            }
                        }
                    }
                }
            }
        }
    }

    .select {
        &.navbar__currency {
            width: 80px;
        }

        &.navbar__language {
            width: 65px;
        }
    }

    .burger {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: auto 0 auto 60px;
        transition: opacity 0.3s;
        cursor: pointer;

        &:hover {
            opacity: 0.6;
        }

        &__icon {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 26px;
            height: 20px;

            span {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 3px;
                border-radius: 2px;
                background-color: #000000;
                transform-origin: center;

                &:nth-child(1) {
                    transform: translate(-50%, calc(-50% - 7px));
                }
                &:nth-child(3) {
                    transform: translate(-50%, calc(-50% + 7px));
                }
            }
        }

        &__name {
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            text-transform: uppercase;
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-lg) {
        &__logo {
            img {
                width: 160px;
            }
        }

        &__country {
            margin-right: 0;
        }

        .burger {
            margin-left: 20px;
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-md) {
        height: 65px;

        &__logo {
            margin-right: auto;

            img {
                width: 150px;
                transition: width 0.3s;
            }
        }

        &.min {
            height: 50px;

            .navbar__logo {
                img {
                    width: 130px;
                }
            }
        }

        .iconbar {
            margin: auto 0;

            &__item {
                width: 24px;
                height: 24px;
                margin: auto 0 auto 20px;

                img {
                    width: 100%;
                }
            }

            .navbar-search {
                display: none;
            }

            .navbar-cart {
                &__counter {
                    right: -8px;
                    top: -8px;
                }
            }
        }

        .select {
            display: none;
        }

        .burger {
            display: none;
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-sm) {
        .iconbar {
            &__item {
                margin-left: 15px;
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-xs) {
        &__logo {
            img {
                width: 120px;
            }
        }
    }
}
