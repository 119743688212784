/**********************************************************************/
/**********************************************************************/
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    z-index: 1200;
    pointer-events: none;
    cursor: pointer;
    transition: opacity 0.3s;
}

/**********************************************************************/
/**********************************************************************/
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 740px;
    max-width: calc(100vw - 40px);
    max-height: calc(100vh - 80px);
    padding: 80px 30px 90px 60px;
    border-radius: 10px;
    box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    pointer-events: none;
    opacity: 0;
    z-index: 1250;
    transition: opacity 0.3s;

    &.show {
        opacity: 1;
        pointer-events: initial;
    }

    /******************************************************************/
    &__close {
        position: absolute;
        top: 25px;
        right: 25px;
        width: 20px;
        height: 20px;
        transition: opacity 0.3s;
        cursor: pointer;

        &:hover {
            opacity: 0.6;
        }

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 20px;
            height: 2px;
            border-radius: 1px;
            background-color: #000000;
        }

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 20px;
            height: 2px;
            border-radius: 1px;
            background-color: #000000;
        }
    }

    /******************************************************************/
    &__content {
        display: flex;
        flex-direction: column;
        padding-right: 30px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__title {
        margin-bottom: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        font-size: 18px;
        line-height: 110%;
        text-transform: uppercase;
        color: #363636;
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 16px;
        line-height: 20px;
        color: #363636;

        a,
        span {
            color: #448a33;
        }
    }

    /******************************************************************/
    /******************************************************************/
    &-register {
        padding: 75px 45px 75px 75px;

        .popup {
            &__title {
                margin-bottom: 35px;
                padding-bottom: 0;
                border-bottom: none;
                font-size: 24px;
                line-height: 100%;
                text-align: center;
                color: #363636;
            }

            &__subtitle {
                width: 370px;
                max-width: 100%;
                margin: -15px auto 40px;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                color: #787878;
            }
        }

        .formarea {
            .checklist {
                justify-content: center;
                margin-bottom: 40px;

                &__item {
                    margin-right: 35px;

                    &:last-child {
                        margin-right: 0;
                    }

                    label {
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 100%;
                        color: #000000;
                        text-transform: initial;

                        &.error {
                            color: #f5362d;
                        }
                    }
                }
            }

            &__submit {
                input {
                    width: 370px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    &-login {
        &__note {
            margin: 40px auto 20px;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #226223;
            text-transform: uppercase;
            text-align: center;
        }

        &__change {
            margin: 0 auto;
            font-size: 14px;
            line-height: 20px;
            color: #bfbfbf;
            text-align: center;

            a {
                color: #787878;
                transition: color 0.3s;

                &:hover {
                    color: #226223;
                }
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    &-region {
        .popup {
            &__title {
                margin: 0 auto 50px;
                padding: 0 30px 20px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                font-size: 24px;
                line-height: 100%;
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    &-message {
        .popup {
            &__img {
                display: flex;
                flex-direction: column;
                margin: 0 auto 50px;

                img {
                    max-width: 105px;
                    max-height: 105px;
                }
            }

            &__title {
                margin-bottom: 20px;
                padding-bottom: 0;
                border-bottom: none;
                font-size: 24px;
                line-height: 100%;
                text-align: center;
                color: #363636;
            }

            &__txt {
                width: 370px;
                max-width: 100%;
                margin: 0 auto;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                color: #787878;
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    &-country {
        align-items: center;
        padding-top: 70px;
        padding-bottom: 70px;

        .popup {
            &__content {
                width: 440px;
                max-width: 100%;
            }

            &__title {
                margin-left: 15px;
                margin-right: 15px;
                padding-bottom: 20px;
                font-size: 24px;
                line-height: normal;
            }
        }

        .countries-list {
            &__items {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px 0;
                margin-bottom: 40px;
            }

            &__btn {
                display: flex;
                align-items: flex-start;

                .btn {
                    min-height: 0;
                    min-width: 0;
                    border-width: 1px !important;
                    background-color: transparent !important;

                    &__name {
                        padding: 8px 10px;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 100%;
                        color: #000000;
                        text-transform: initial;
                        transition: color 0.3s;
                    }

                    &:hover,
                    &.is-active {
                        border-color: #40b04e;

                        .btn__name {
                            color: #40b04e;
                        }
                    }
                }
            }

            &__save {
                margin-left: 15px;
                margin-right: 15px;
                min-height: 50px;
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-md) {
        padding: 40px 10px 30px 20px;

        /**************************************************************/
        &__close {
            position: absolute;
            top: 15px;
            right: 15px;
        }

        /**************************************************************/
        &__content {
            padding-right: 10px;
        }

        &__title {
            margin-bottom: 20px;
            font-size: 16px;
        }

        /**************************************************************/
        /**************************************************************/
        &-register {
            .popup {
                &__title {
                    margin-bottom: 20px;
                    font-size: 20px;
                }

                &__subtitle {
                    margin-top: 0;
                    margin-bottom: 20px;
                }
            }

            .formarea {
                .checklist {
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 20px;

                    &__item {
                        margin-right: 0;
                    }
                }
            }
        }

        /**************************************************************/
        /**************************************************************/
        &-login {
            &__note {
                margin-top: 30px;
                margin-bottom: 10px;
            }
        }

        /**************************************************************/
        /**************************************************************/
        &-region {
            .popup {
                &__title {
                    margin-bottom: 30px;
                    padding: 0 0 10px;
                    font-size: 18px;
                }
            }

            .formarea {
                .checklist {
                    margin-bottom: 40px;
                }
            }
        }

        /**************************************************************/
        /**************************************************************/
        &-message {
            .popup {
                &__img {
                    margin-bottom: 30px;

                    img {
                        max-width: 75px;
                        max-height: 75px;
                    }
                }

                &__title {
                    font-size: 18px;
                }
            }
        }

        /**************************************************************/
        /**************************************************************/
        &-country {
            .countries-list {
                &__items {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    margin-bottom: 20px;
                }

                &__save {
                    min-height: 40px;
                }
            }
        }
    }
}
