/**********************************************************************/
/**********************************************************************/
.page-history {
	/******************************************************************/
	&__content {
		&:before {
			width: 100%;
		}
	}

	/******************************************************************/
	&__total {
		position: absolute;
		bottom: 0;
		right: 0;
		font-size: 16px;
		line-height: 20px;
		color: #787878;
	}

	/******************************************************************/
	.history-items {
		display: flex;
		flex-direction: column;

		.histitem {
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			margin-bottom: 40px;


			/**********************************************************/
			/**********************************************************/
			&__head {
				display: flex;
				justify-content: space-between;
				margin-bottom: 40px;
			}

			&__info {
				width: calc(100% - 165px);
			}

			&__date {
				margin-bottom: 20px;
				font-weight: 300;
				font-size: 24px;
				line-height: 25px;
				color: #003201;
			}

			&__info-row {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: calc(100% + 20px);
				margin: 0 -10px;
			}

			&__info-block {
				display: flex;
				margin: 10px;
				font-size: 14px;
				line-height: 20px;
				color: #787878;
				text-transform: uppercase;

				span {
					margin-left: 10px;
					font-size: 16px;
					color: #868686;
					text-transform: initial;

					&.green {
						color: #448A33;
					}

					&.grey {
						color: #BFBFBF;
					}

					&.lg {
						font-size: 20px;
						color: #000000;
					}
				}
			}

			&__nav {
				display: flex;
				flex-direction: column;
				width: 135px;
			}

			&__more {
				display: flex;
				align-items: center;
				margin: auto auto 0;
				font-size: 14px;
				line-height: 20px;
				color: #787878;
				transition: opacity 0.3s;
				cursor: pointer;

				img {
					width: 10px;
					margin: auto 0 auto 10px;
					transition: transform 0.3s;
				}

				&:hover {
					opacity: 0.6;
				}
			}

			&__btn {
				width: 100%;
				min-height: 40px;
				min-width: 100%;
				margin: 20px auto 0;
				border-radius: 20px;

				&__name {
					font-size: 12px;
				}
			}

			&.open {
				.histitem__more {
					img {
						transform: scaleY(-1);
					}
				}
			}


			/**********************************************************/
			/**********************************************************/
			&__body {
				display: none;
				border-top: 1px solid rgba(0, 0, 0, 0.2);
				padding-top: 30px;

				.histitem-product {
					margin-bottom: 20px;

					.row {
						justify-content: flex-start;
						width: calc(100% - 165px);

						.col {
							display: flex;
							flex-direction: column;
							margin-bottom: 20px;
						}
					}

					&__title {
						margin-right: auto;
						font-weight: 300;
						font-size: 18px;
						line-height: 20px;
						color: #003201;
						text-transform: uppercase;
						transition: opacity 0.3s;

						&:hover {
							opacity: 0.6;
						}
					}

					&__meta {
						display: flex;
						font-size: 14px;
						line-height: 20px;
						color: #787878;
						text-transform: uppercase;

						span {
							margin-left: 10px;
							font-size: 16px;
							color: #000000;
							text-transform: initial;
						}
					}

					&__img {
						width: 135px;
						max-width: 100%;
						margin-right: auto;
						transition: opacity 0.3s;

						&:hover {
							opacity: 0.6;
						}
					}

					&__descr {
						display: flex;
						flex-direction: column;

						p {
							margin-bottom: 10px;
							font-size: 14px;
							line-height: 110%;
							color: #363636;

							&:last-child {
								margin-bottom: 0;
							}

							span {
								margin-left: 5px;
								color: #787878;
							}
						}
					}
				}
			}
		}
	}


	/******************************************************************/
	/******************************************************************/
	@include is-screen-max($breakpoint-lg) {
		/**************************************************************/
		&__total {
			position: relative;
			right: initial;
			margin-top: 5px;
		}

		/**************************************************************/
		.history-items {
			.histitem {
				&__body {
					.histitem-product {
						.row {
							width: 100%;
						}
					}
				}
			}
		}
	}
	

	/******************************************************************/
	/******************************************************************/
	@include is-screen-max($breakpoint-md) {
		/**************************************************************/
		&__total {
			font-size: 14px;
		}

		/**************************************************************/
		.history-items {
			.histitem {
				margin-bottom: 20px;


				/******************************************************/
				/******************************************************/
				&__head {
					flex-direction: column;
					margin-bottom: 20px;
				}

				&__info {
					width: 100%;
				}

				&__date {
					margin-bottom: 10px;
					font-size: 20px;
					line-height: 20px;
				}

				&__info-row {
					width: calc(100% + 10px);
					margin: 0 -5px;
				}

				&__info-block {
					margin: 5px;

					span {
						&.lg {
							font-size: 18px;
						}
					}
				}

				&__nav {
					flex-direction: row;
					justify-content: space-between;
					width: 100%;
					margin-top: 10px;
				}

				&__more {
					margin: auto 0;
				}

				&__btn {
					width: 135px;
					min-width: initial;
					margin: auto 0;
				}
			}
		}
	}
}