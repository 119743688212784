/**********************************************************************/
/**********************************************************************/
.formarea {
    max-width: 100%;

    &__title {
        margin-bottom: 40px;
        font-size: 18px;
        line-height: 24px;
        color: #363636;
        text-transform: uppercase;
    }

    &__subtitle {
        margin: 20px 0;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
    }

    &__form {
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 100;
    }

    &__input {
        margin-bottom: 20px;

        input,
        textarea {
            display: block;
            width: 100%;
            padding: 14px;
            border: 1px solid #bfbfbf;
            border-radius: 4px;
            background-color: #ffffff;
            font-family: "Muller", sans-serif;
            font-size: 16px;
            line-height: 20px;
            color: #787878;
            outline: none;
            appearance: none;
            transition: border-color 0.3s, color 0.3s;

            &::placeholder {
                color: #bfbfbf;
            }

            &.error {
                border-color: #f5362d;
            }
        }

        textarea {
            height: 140px;
            resize: none;
        }

        label {
            display: block;

            &.error {
                margin: 5px 0 0;
                color: #f5362d;
            }
        }

        &--file {
            label {
                position: relative;
                transition: opacity 0.3s;
                cursor: pointer;

                &:hover {
                    opacity: 0.6;
                }

                & > img {
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 20px;
                    pointer-events: none;
                }

                input {
                    &[type="text"] {
                        padding-left: 40px;
                        pointer-events: none;
                    }

                    &[type="file"] {
                        display: none;
                    }
                }
            }
        }

        &--password {
            position: relative;

            input {
                padding-right: 44px;

                &[type="password"] {
                    & ~ .formarea {
                        &__input-icon {
                            img {
                                &:nth-child(2) {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }

                &[type="text"] {
                    & ~ .formarea {
                        &__input-icon {
                            img {
                                &:nth-child(1) {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }
            }

            .formarea {
                &__input-icon {
                    position: absolute;
                    top: 25px;
                    right: 10px;
                    transform: translateY(-50%);
                    width: 30px;
                    height: 30px;
                    transition: opacity 0.3s;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.6;
                    }

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 24px;
                    }
                }
            }
        }
    }

    &__submit {
        margin-top: 30px;
        margin-right: auto;

        &.disabled {
            pointer-events: none;
        }

        input {
            display: block;
            width: 220px;
            height: 50px;
            max-width: 100%;
            border: 4px solid transparent;
            border-radius: 25px;
            background-color: #226223;
            font: normal 700 14px / 42px "Muller", sans-serif;
            color: #ffffff;
            text-transform: uppercase;
            text-align: center;
            cursor: pointer;
            transition: border-color 0.3s;

            &:hover {
                border-color: #199629;
            }
        }
    }

    &__answer {
        display: none;
        width: 220px;
        max-width: 100%;
        margin: 20px auto 0;
        padding: 6px 10px 4px;
        border: 2px solid #328d1d;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
        color: #328d1d;

        &.show {
            display: block;
        }

        &.error {
            border-color: #f5362d;
            color: #f5362d;
        }
    }

    &__img {
        position: absolute;
        right: -180px;
        bottom: -15px;
        width: 700px;
        max-width: initial;
        transform: rotate(-5deg);
    }

    &.narrow {
        & > * {
            width: 370px;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .formarea {
            &__submit {
                display: flex;
                justify-content: center;
            }
        }
    }

    /**************************************************************/
    /**************************************************************/
    @include is-screen-max($breakpoint-md) {
        &__title {
            margin-bottom: 30px;
        }

        &__submit {
            margin-top: 0;
            margin-left: auto;
        }

        &__img {
            display: none;
        }
    }
}

.iti {
    width: 100%;

    &--container {
        z-index: 1350;

        .iti {
            &__dropdown-content {
                top: 0;
                width: 100%;
                max-height: initial;
            }
        }
    }

    &__selected-flag {
        padding: 0 8px 0 14px;
        background-color: transparent !important;
        outline: none;
    }

    &__selected-dial-code {
        margin-left: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #787878;
    }

    &__arrow {
        margin-left: 4px;
        width: 8px;
        height: 8px;
        background-image: url("../../../media/img/icons/triangle.svg");
        background-size: 100%;
        border: none !important;
        transition: transform 0.3s;

        &--up {
            transform: scaleY(-1);
        }
    }

    &__flag {
        background-image: url("../../../media/img/icons/flags.png");
        background-size: 5762px 15px;

        &-container {
            height: 50px;
        }

        &-box {
            width: auto;
            margin-right: 8px;
        }
    }

    &__dropdown-content {
        top: calc(100% + 1px);
        width: 300px;
        max-height: 160px;
        border-color: #bfbfbf;
        border-radius: 4px;
        box-shadow: none;
        overflow: hidden auto;
    }

    &__country {
        padding: 8px 14px;
        font-size: 16px;
        line-height: 20px;
        color: #787878;
    }
}
