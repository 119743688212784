/**********************************************************************/
/**********************************************************************/
.page-dealers {
	margin-bottom: 190px;

	&__titlearea {
		margin: 50px 0 30px;
	}

	&__title {
		margin: 20px 0 0;
		font-weight: 300;
		font-size: 36px;
		line-height: 130%;
		color: #003201;
		text-transform: uppercase;
	}

	/******************************************************************/
	&__body {
		display: flex;
		justify-content: space-between;
	}

	&__map {
		width: calc(50% - 85px);
		height: 400px;

		&:not(.is-active) {
			display: none;
		}
	}

	.dealers {
		width: calc(50% - 10px);

		&__select {
			margin: 0 0 30px;

			.select {
				&__selected {
					padding: 16px 40px 16px 20px;
					border: 1px solid #BFBFBF;
					border-radius: 4px;
					font-size: 16px;
					text-transform: uppercase;
			
					img {
						right: 21px;
						width: 14px;
					}
				}
			
				&__items {
					display: flex;
					flex-direction: column;
					gap: 8px;
					padding: 20px;
					border: 1px solid #BFBFBF;
					border-top: none;
					border-bottom-left-radius: 4px;
					border-bottom-right-radius: 4px;
				}
			
				&__item {
					padding: 4px 0;
					font-family: "Muller", sans-serif;
					font-weight: 400;
					font-size: 16px;
					line-height: 24px;
					color: #787878;
					text-transform: uppercase;
			
					&:hover {
						color: #448A33;
					}
			
					&.same {
						display: initial;
						color: #448A33;
					}
				}
			}
		
			&.open {
				.select {
					&__selected {
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
					}
				}
			}
		}

		&__block {
			&:not(.is-active) {
				display: none;
			}

			.dealer {
				display: flex;
				flex-direction: column;
				margin-bottom: 15px;
				padding-bottom: 15px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);

				&:last-child {
					margin-bottom: 0;
					padding-bottom: 0;
					border: none;
				}

				&__name {
					margin-right: auto;
					margin-bottom: 15px;
					font-size: 16px;
					line-height: 20px;
					color: #000000;
					text-transform: uppercase;
					transition: color 0.3s;
					cursor: pointer;

					&:hover {
						color: #448A33;
					}
				}

				&-line {
					margin-bottom: 10px;
					font-weight: 300;
					font-size: 16px;
					line-height: 24px;
					color: #000000;

					&__title {
						margin-right: 5px;
						font-weight: 400;
					}

					a {
						color: #000000;
						transition: color 0.3s;

						&:hover {
							color: #448A33;
						}
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}


	/******************************************************************/
	/******************************************************************/
	@include is-screen-max($breakpoint-lg) {
		&__map {
			width: calc(50% - 20px);
		}
	}


	/******************************************************************/
	/******************************************************************/
	@include is-screen-max($breakpoint-md) {
		margin-bottom: 60px;

		&__titlearea {
			margin: 20px 0;
		}

		&__title {
			font-size: 24px;
		}

		/**************************************************************/
		&__body {
			flex-direction: column;
		}

		&__map {
			width: 100%;
			height: 300px;
			order: 2;
		}

		.dealers {
			width: 100%;
			order: 1;
			margin-bottom: 30px;

			&__select {
				.select {
					&__selected {
						padding: 15px 30px 15px 20px;
				
						img {
							right: 20px;
							width: 12px;
						}
					}
				
					&__items {
						padding: 15px 20px;
					}
				
					&__item {
						line-height: 20px;
					}
				}
			}

			.dealer {
				&__name {
					margin-bottom: 10px;
					font-size: 14px;
					line-height: 18px;
				}

				&-line {
					margin-bottom: 5px;
					font-size: 14px;
					line-height: 18px;
				}
			}
		}
	}
}