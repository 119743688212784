.page-author {
    margin-top: 50px;
    margin-bottom: 110px;

    @include is-screen-max($breakpoint-md) {
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .breadcrumbs {
        margin-bottom: 30px;
    }

    .author-profile {
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin-bottom: 40px;

        @include is-screen-max($breakpoint-md) {
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
    }
}
