/**********************************************************************/
/**********************************************************************/
.footer {
    display: flex;
    flex-direction: column;

    &__content {
        position: relative;
        padding: 60px 0 40px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50vw);
            width: 100vw;
            background-color: #dedede;
            z-index: -1;
        }
    }

    /******************************************************************/
    &__subtitle {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        text-transform: uppercase;
    }

    .menu {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;

        &__item {
            display: flex;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                margin-right: auto;
                font-size: 13px;
                line-height: 17px;
                color: #424242;
                transition: opacity 0.3s;

                &:hover {
                    opacity: 0.6;
                }
            }
        }
    }

    &__download {
        min-width: 0;
        min-height: 30px;
        margin-top: 20px;

        .btn {
            &__name {
                padding: 2px 12px 0;
                font-size: 11px;
            }
        }
    }

    /******************************************************************/
    &__info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 40px;

        .footer-list {
            display: flex;
            flex-direction: column;
            width: calc(100% - 300px);
            margin-top: 5px;

            &__item {
                position: relative;
                display: flex;
                margin-bottom: 15px;
                margin-right: auto;
                padding-left: 30px;
                transition: opacity 0.3s;

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    opacity: 0.6;
                }

                & > img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 20px;
                }

                span {
                    font-size: 13px;
                    line-height: 20px;
                    color: #424242;
                }
            }

            &__socials {
                display: flex;
                align-items: center;
                align-self: flex-start;
                gap: 5px;
                margin-left: 10px;

                img {
                    max-height: 20px;
                }
            }
        }

        .footer__payment {
            display: flex;
            flex-direction: column;

            img {
                width: 260px;
                margin: 0 auto;
            }
        }
    }

    .socials {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-bottom: 20px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
        }

        &__list {
            display: flex;
            align-items: center;
            gap: 12px;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 28px;
                height: 28px;
                transition: opacity 0.3s;

                &:hover {
                    opacity: 0.6;
                }

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }

    &__company {
        p {
            font-size: 12px;
            line-height: 20px;
            color: #424242;
        }
    }

    /******************************************************************/
    .btmbar {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px 20px;
        min-height: 30px;
        padding: 5px 0;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50vw);
            width: 100vw;
            background-color: #2f2f2f;
            z-index: -1;
        }

        &__cpr {
            font-size: 13px;
            line-height: 20px;
            color: #868686;
            text-transform: uppercase;
        }

        &__links {
            display: flex;
            justify-content: center;
            gap: 5px 20px;
            padding: 0;
            list-style: none;

            @include is-screen-max($breakpoint-xl) {
                flex-direction: column;
                align-items: center;

                a {
                    &:before {
                        display: none;
                    }
                }
            }

            li {
                position: relative;

                &:first-child {
                    &:before {
                        content: "";
                        position: absolute;
                        right: -10px;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 1px;
                        height: 10px;
                        background-color: #868686;
                    }
                }

                a {
                    font-size: 13px;
                    line-height: 20px;
                    color: #868686;
                    text-align: center;
                    transition: color 0.3s;

                    &:hover {
                        color: #ffffff;
                    }
                }
            }
        }

        &__f16 {
            display: flex;
            align-items: center;
            font-size: 13px;
            line-height: 20px;
            color: #868686;
            transition: color 0.3s;

            img {
                width: 50px;
                margin: auto 0 auto 5px;
            }

            &:hover {
                color: #ffffff;
            }
        }
    }

    /******************************************************************/
    &__scrollup {
        position: fixed;
        right: calc(50vw - 560px);
        bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #226223;
        transition: transform 0.3s, opacity 0.3s;
        cursor: pointer;
        z-index: 200;

        @include is-screen-max($breakpoint-xl) {
            right: 40px;
            bottom: 40px;
        }

        @include is-screen-max($breakpoint-md) {
            right: 20px;
            bottom: 20px;
            width: 40px;
            height: 40px;
        }

        @include is-screen-max($breakpoint-sm) {
            display: none;
        }

        &.hide {
            display: flex !important;
            opacity: 0;
            pointer-events: none;
        }

        &:hover {
            transform: scale(1.1);
        }

        img {
            width: 14px;
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-lg) {
        &__content {
            .col {
                &-3 {
                    width: 50% !important;
                }

                &-6 {
                    width: 100% !important;
                }
            }
        }

        /**************************************************************/
        &__info {
            margin-bottom: 30px;
            padding-top: 30px;

            .footer-list {
                width: 50%;
            }

            .footer__payment {
                width: 50%;

                img {
                    margin-left: 0;
                }
            }
        }

        /**************************************************************/
        .btmbar {
            flex-direction: column;
            height: auto;
            padding: 20px 0;

            &__links {
                li {
                    &:first-child {
                        &:before {
                            display: none;
                        }
                    }
                }
            }

            &__cpr {
                text-align: center;
            }

            &__f16 {
                text-align: center;
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-md) {
        &__content {
            width: 350px !important;
            padding-bottom: 60px;

            .col {
                &-3 {
                    width: 100% !important;
                }

                &-6 {
                    position: relative;
                    padding-bottom: 140px;
                }
            }
        }

        /**************************************************************/
        .menu {
            margin-bottom: 30px;
        }

        &__download {
            margin-top: 0;
            margin-bottom: 30px;
        }

        /**************************************************************/
        &__info {
            flex-direction: column;
            margin-bottom: 0;
            padding-top: 10px;

            .footer-list {
                width: 100%;
                margin-top: 0;
                margin-bottom: 40px;
            }

            .footer__payment {
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;

                img {
                    width: auto;
                    height: 100px;
                    margin-left: auto;
                }
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-sm) {
        &__content {
            width: 250px !important;
        }
    }
}
