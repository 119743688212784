/**********************************************************************/
/**********************************************************************/
.product-card {
    position: relative;
    display: flex;
    flex-direction: column;

    &__img {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 260px;
        transition: opacity 0.3s;
        z-index: 10;

        @include is-screen-max($breakpoint-md) {
            height: 240px;
        }

        &:hover {
            opacity: 0.6;
        }

        img {
            max-height: 100%;
            margin: auto;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        max-width: 190px;
        margin: -50px 0 auto auto;
        z-index: 20;

        @include is-screen-max($breakpoint-md) {
            max-width: 150px;
            margin-top: -30px;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__title {
        margin-bottom: 10px;
        font-weight: 300;
        font-size: 24px;
        line-height: 25px;
        color: #363636;
        text-transform: uppercase;
        transition: opacity 0.3s;
        cursor: pointer;

        @include is-screen-max($breakpoint-md) {
            font-size: 18px;
            line-height: 20px;
        }

        &:hover {
            opacity: 0.6;
        }
    }

    &__price {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        p {
            margin-right: auto;
            font-size: 14px;
            line-height: 110%;
            color: #363636;
            text-align: center;

            &.old {
                position: relative;
                margin-bottom: 5px;
                font-size: 12px;

                &:before {
                    content: "";
                    position: absolute;
                    left: -10px;
                    right: -10px;
                    top: 50%;
                    transform: translateY(-3px) rotate(-3deg);
                    height: 2px;
                    background-color: #e8988d;
                }
            }
        }
    }
}
