.meta_btn {
    position: fixed;
    left: 46%;
    top: 0;
    z-index: 999999999;
    border: none;
    background: #ff0000;
    color: #fff;
    padding: 5px;
    cursor: pointer;
}

.meta_modal {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999999;
    border: none;
    background: #404040;
    color: #fff;
    padding: 0;
    width: 100%;
    height: 0;
    display: none;

    .h1, .h2, .h3, .h4, .h5, h1, h2, h3, h4, h5 {
        color: #000!important;
    }

    .meta_field_group_check {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        label {
            margin-left: 3px;
        }
    }

    .meta_field_group {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        input, textarea {
            border: 0;
            margin-top: 5px;
            padding: 7px;
        }
    }
}

.meta_modal_show {
    height: auto;
    display: block;
    padding: 40px 20px 5px;
}
