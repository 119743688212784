/**********************************************************************/
/**********************************************************************/
.page-about {
    margin-bottom: 190px;

    /******************************************************************/
    @include is-screen-max($breakpoint-md) {
        margin-bottom: 60px;
    }

    /******************************************************************/
    /******************************************************************/
    .videobanner {
        width: 100vw;
        margin: 0 calc(50% - 50vw) 145px;

        &__content {
            display: flex;
            flex-direction: column;
            width: 1440px;
            max-width: 100%;
            margin: 0 auto;
        }

        .video {
            position: relative;
            display: flex;
            flex-direction: column;

            video {
                width: 100%;
                height: auto;
            }

            &__play {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                width: 150px;
                height: 150px;
                transition: transform 0.3s, opacity 0.3s;
                cursor: pointer;

                &:hover {
                    transform: translate(-50%, -50%) scale(1.2);
                }

                img {
                    width: 50px;
                    margin: auto;
                }
            }

            &__pause {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                pointer-events: none;
            }

            &.on {
                .video {
                    &__play {
                        opacity: 0;
                        pointer-events: none;
                    }

                    &__pause {
                        pointer-events: initial;
                    }
                }
            }
        }

        &__info {
            position: relative;
            min-height: 80px;
            padding: 20px 0;
            background-color: #226223;
        }

        &__wrap {
            display: flex;
            justify-content: space-between;
        }

        &__img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 165px;
            height: 165px;
            border-radius: 50%;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
            background-color: #f8f8f8;
            overflow: hidden;

            img {
                margin: auto;
            }
        }

        &__txt {
            width: calc(50% - 120px);
            margin: auto 0;

            p {
                font-size: 14px;
                line-height: 20px;
                color: #ffffff;
                text-transform: uppercase;
            }

            &_l {
                p {
                    text-align: right;
                }
            }
        }

        /*************************************************************/
        @include is-screen-max($breakpoint-md) {
            margin-bottom: 60px;

            .video {
                &__play {
                    width: 80px;
                    height: 80px;

                    img {
                        width: 40px;
                    }
                }
            }

            &__info {
                padding: 50px 0 10px;
            }

            &__wrap {
                flex-direction: column;
            }

            &__img {
                top: 0;
                width: 60px;
                height: 60px;
            }

            &__txt {
                width: 100%;
                margin: 0 0 20px;

                p {
                    font-size: 14px;
                    text-align: center;
                }
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    .infoarea {
        margin-bottom: 120px;

        &__title {
            width: 700px;
            max-width: 100%;
            margin: 0 auto 50px;
            font-weight: 300;
            font-size: 36px;
            line-height: 150%;
            color: #226223;
            text-transform: uppercase;
            text-align: center;
        }

        &__txt {
            p {
                font-weight: 300;
                font-size: 16px;
                line-height: 170%;
                color: #000000;
                text-align: center;
            }
        }

        /*************************************************************/
        @include is-screen-max($breakpoint-md) {
            margin-bottom: 60px;

            &__title {
                margin-bottom: 30px;
                font-size: 24px;
                line-height: 140%;
            }

            &__txt {
                p {
                    font-size: 14px;
                    line-height: 150%;
                }
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    .stats {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        z-index: 100;

        .stat {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 180px;
            margin: 0 60px;

            @include is-screen-max($breakpoint-lg) {
                width: 160px;
                margin: 0 30px;
            }

            img {
                position: absolute;
                left: -35px;
                top: -15px;
                width: 165px;
                z-index: -1;
            }

            &__num {
                font-weight: 100;
                font-size: 110px;
                line-height: 110%;

                span {
                    margin-left: 5px;
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 100%;
                }
            }

            &:nth-child(1) .stat__num {
                color: #e8988d;
            }
            &:nth-child(2) .stat__num {
                color: #98ca8c;
            }
            &:nth-child(3) .stat__num {
                color: #9488c6;
            }

            &__txt {
                margin: 0 0 auto 10px;
                font-weight: 300;
                font-size: 20px;
                line-height: 110%;
                color: #363636;
                text-transform: uppercase;
            }
        }

        /**************************************************************/
        @include is-screen-max($breakpoint-md) {
            flex-direction: column;
            margin-left: 50px;

            .stat {
                width: 160px;
                margin: 0 auto 40px;

                &:last-child {
                    margin-bottom: 0;
                }

                img {
                    left: -20px;
                    top: -10px;
                    width: 140px;
                }

                &__num {
                    font-size: 90px;

                    span {
                        font-size: 24px;
                    }
                }

                &__txt {
                    font-size: 16px;
                }
            }
        }
    }
}
