.pagination {
    display: flex;
    flex-wrap: wrap;

    &__arr {
        display: flex;
        flex-direction: column;
        width: 24px;
        height: 24px;
        transition: opacity 0.3s;

        img {
            width: 16px;
            margin: auto;
        }

        &_l {
            img {
                transform: scaleX(-1);
            }
        }

        &:hover {
            opacity: 0.6;
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.6;
        }
    }

    &__list {
        display: flex;
        margin: auto 6px;
    }

    &__link {
        padding: 0 6px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #787878;
        transition: color 0.3s;

        &:hover {
            color: #494949;
        }
    }

    &__curr {
        margin: 0 6px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #494949;
    }

    &__more {
        margin: 0 6px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #787878;
    }

    @include is-screen-max($breakpoint-lg) {
        justify-content: center;
    }
}
