.grid {
    & > .row {
        justify-content: flex-start;
        row-gap: 70px;

        @include is-screen-max($breakpoint-md) {
            row-gap: 20px;
        }

        @include is-screen-max(580px) {
            justify-content: center;
        }
    }

    &.is-rows {
        @include is-screen-min($breakpoint-md) {
            & > .row {
                row-gap: 60px;
            }
        }
    }

    .product-card {
        @include is-screen-max($breakpoint-md) {
            padding-bottom: 35px;

            &:nth-child(2n + 1) {
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    left: 15px;
                    bottom: 0;
                    width: calc(100vw - 40px);
                    height: 1px;
                    background-color: rgba(0, 0, 0, 0.2);
                }
            }
        }

        &__img {
            height: 190px;

            img {
                margin-left: 0;
            }
        }

        &__body {
            max-width: 140px;
            margin-top: -15px;

            @include is-screen-max($breakpoint-lg) {
                position: relative;
            }

            @include is-screen-max($breakpoint-md) {
                max-width: 150px;
            }
        }

        &__info {
            flex-grow: 1;
        }

        &__title {
            margin-bottom: 8px;
            font-size: 18px;
            line-height: 20px;
        }

        &__subtitle {
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 110%;
            color: #448a33;
        }

        &__descr {
            display: flex;
            flex-direction: column;
            min-height: 60px;

            p {
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 110%;
                color: #363636;

                @include is-screen-max($breakpoint-md) {
                    margin-bottom: 5px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                span {
                    color: #787878;
                }
            }
        }

        &__btncol {
            position: absolute;
            left: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            width: 120px;

            @include is-screen-max($breakpoint-xl) {
                width: auto;
            }

            @include is-screen-max($breakpoint-lg) {
                transform: translateX(calc(-100% - 20px));
            }
        }

        &__price {
            margin: 0 auto 14px;

            @include is-screen-max($breakpoint-xl) {
                margin-bottom: 10px;
            }

            @include is-screen-max($breakpoint-lg) {
                margin-left: 0;
            }

            @include is-screen-max($breakpoint-md) {
                margin-bottom: 10px;
            }

            p {
                margin-left: 0;
                margin-right: 0;
                font-size: 18px;
                font-weight: 500;

                @include is-screen-max($breakpoint-md) {
                    margin-left: 0;
                }
            }

            &-note {
                font-weight: 400 !important;
                font-size: 14px !important;
                line-height: 110%;
                color: #424242;
                text-align: left;
                text-transform: lowercase;
            }
        }

        .quantity {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 80px;
            height: 30px;
            padding: 0 10px;
            margin: 0 auto 8px;
            border-radius: 50px;
            border: 1px solid rgba(47, 47, 47, 0.2);
            background: #ffffff;

            @include is-screen-max($breakpoint-xl) {
                margin-left: 0;
            }

            @include is-screen-max($breakpoint-md) {
                margin-left: auto;
            }

            &__btn {
                position: relative;
                flex-shrink: 0;
                width: 10px;
                height: 10px;
                transition: opacity 0.3s;
                cursor: pointer;

                @include is-screen-max($breakpoint-xl) {
                    margin-left: 0;
                }

                @include is-screen-max($breakpoint-md) {
                    margin-left: auto;
                }

                &:hover {
                    opacity: 0.6;
                }

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 1px;
                    background-color: #787878;
                }

                &::before {
                    width: 9px;
                    height: 1px;
                }

                &::after {
                    width: 1px;
                    height: 9px;
                }

                &.minus {
                    &::after {
                        display: none;
                    }
                }
            }

            input {
                min-width: 0;
                padding: 0 15px 0 0;
                border: none;
                background-color: transparent;
                font-family: "Muller", sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #868686;
                text-align: center;
                outline: none;
                pointer-events: none;
                -moz-appearance: textfield;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    margin: 0;
                    -webkit-appearance: none;
                }
            }

            &::after {
                content: "уп";
                position: absolute;
                top: 50%;
                right: 24px;
                transform: translateY(-50%);
                font-weight: 400;
                font-size: 12px;
                line-height: 100%;
                color: #868686;
                pointer-events: none;
            }
        }

        &__btn {
            margin: 0 auto;
        }

        // @include is-screen-max(580px) {
        //     &__img {
        //         img {
        //             margin-left: auto;
        //         }
        //     }

        //     &__descr {
        //         margin-bottom: 10px;
        //     }

        //     &__btncol {
        //         position: relative;
        //         left: initial;
        //         bottom: initial;
        //         transform: none;
        //     }

        //     &__price {
        //         margin-left: auto;
        //     }
        // }

        // @include is-screen-max($breakpoint-sm) {
        //     &__img {
        //         height: 240px;
        //     }

        //     &__descr {
        //         margin-bottom: 0;
        //     }

        //     &__btncol {
        //         position: absolute;
        //         left: 0;
        //         bottom: 5px;
        //         transform: translateX(calc(-100% - 20px));
        //     }

        //     &__price {
        //         margin-left: 0;
        //     }
        // }

        @include is-screen-max($breakpoint-md) {
            .product-card {
                &__img {
                    height: 150px;

                    img {
                        margin-left: auto;
                    }
                }

                &__body {
                    flex-grow: 1;
                    width: 280px;
                    max-width: 100%;
                    margin: 7px auto 0;
                }

                &__info {
                    flex-grow: 0;
                }

                &__title,
                &__subtitle {
                    margin-bottom: 16px;
                }

                &__descr {
                    min-height: 0;
                }

                &__btncol {
                    position: relative;
                    left: initial;
                    bottom: initial;
                    width: 100%;
                    transform: none;
                    margin-top: auto;
                }

                &__price {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 5px 20px;
                    margin: 20px 0;

                    .old {
                        margin-bottom: 0;
                    }
                }

                &__quantity {
                    width: 100%;
                    height: 40px;
                    margin-bottom: 10px;

                    &::after {
                        right: calc(50% - 20px);
                    }
                }

                &__btn {
                    width: 100%;
                    min-height: 40px !important;
                    border-radius: 20px !important;
                }
            }
        }

        &--row {
            @include is-screen-min($breakpoint-md) {
                position: relative;
                flex-direction: row !important;
                gap: 40px;
                width: 100% !important;

                &:not(:last-child) {
                    &::after {
                        content: "";
                        position: absolute;
                        left: 10px;
                        right: 10px;
                        bottom: -30px;
                        height: 1px;
                        background-color: rgba(0, 0, 0, 0.2);
                    }
                }

                .product-card {
                    &__img {
                        flex-shrink: 0;
                        width: 180px;
                        height: 180px;
                    }

                    &__body {
                        flex-direction: row;
                        flex: 1 1 0;
                        gap: 40px;
                        max-width: initial;
                        min-width: 0;
                        margin: 0 auto 0 0;
                    }

                    &__info {
                        flex-grow: 0;
                        width: 360px;
                        max-width: 100%;
                        margin-right: auto;
                    }

                    &__title {
                        margin-bottom: 10px;
                        font-size: 24px;
                        line-height: 120%;
                    }

                    &__subtitle {
                        margin-bottom: 30px;
                        font-size: 16px;
                        color: #448a33;
                    }

                    &__descr {
                        display: grid;
                        grid-template: repeat(2, 1fr) / repeat(2, 1fr);
                        gap: 8px 40px;
                        min-height: 0;

                        @include is-screen-max($breakpoint-xl) {
                            column-gap: 20px;
                            margin-bottom: 0;
                        }

                        p {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 0 10px;
                            margin-bottom: 0;
                            font-size: 16px;

                            span {
                                color: #686b6c;
                            }
                        }
                    }

                    &__btncol {
                        position: relative;
                        left: initial;
                        bottom: initial;
                        transform: none;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        flex-shrink: 0;
                        width: 120px;
                        min-width: 126px;

                        @include is-screen-max($breakpoint-xl) {
                            margin-top: 0;
                        }
                    }

                    &__price {
                        margin-bottom: 22px;

                        p {
                            font-size: 24px;
                            text-align: center;

                            &.old {
                                font-size: 20px;
                            }

                            span {
                                font-size: 16px;
                            }
                        }

                        &-note {
                            color: #448a33;
                        }
                    }

                    &__quantity {
                        width: 100%;
                        height: 50px;
                        margin: 0 0 14px;
                        padding: 0 17px;

                        .quantity {
                            &__btn {
                                width: 13px;
                                height: 13px;

                                &::before {
                                    width: 13px;
                                }

                                &::after {
                                    height: 13px;
                                }
                            }
                        }

                        input {
                            padding-right: 20px;
                            font-size: 16px;
                        }

                        &::after {
                            right: 45px;
                            font-size: 14px;
                        }
                    }

                    &__btn {
                        min-height: 50px !important;
                        width: 100%;
                        border-radius: 30px !important;
                    }
                }
            }
        }
    }
}
