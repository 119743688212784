/**********************************************************************/
/**********************************************************************/
.topbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    z-index: 300;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50vw);
        width: 100vw;
        background-color: #323232;
        z-index: -1;
    }

    &__burger {
        position: relative;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 18px;
        margin: auto auto auto 0;
        transition: opacity 0.3s;
        cursor: pointer;

        &:hover {
            opacity: 0.6;
        }

        span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 2px;
            border-radius: 2px;
            background-color: #ffffff;
            transform-origin: center;

            &:nth-child(1) {
                transform: translate(-50%, calc(-50% - 6px));
            }
            &:nth-child(3) {
                transform: translate(-50%, calc(-50% + 6px));
            }
        }
    }

    &__nav {
        display: flex;
        margin: auto auto auto 0;

        ul {
            display: flex;
            gap: 10px 40px;
            padding: 0;
            list-style: none;
        }
    }

    &__item {
        display: flex;
        align-items: center;

        &[href] {
            transition: opacity 0.3s;

            &:hover {
                opacity: 0.6;
            }
        }

        img {
            width: 18px;
            margin: auto 10px auto 0;
        }

        span {
            font-size: 14px;
            line-height: 130%;
            color: #ffffff;
        }
    }

    &-select {
        position: relative;

        &__mobicon {
            display: none;
        }

        &__main {
            display: flex;
            align-items: center;

            a {
                font-size: 14px;
                line-height: 40px;
                color: #ffffff;
                transition: opacity 0.3s;

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        &__socials {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-right: 10px;

            img {
                max-height: 20px;
            }
        }

        &__icon {
            display: flex;
            flex-direction: column;
            width: 35px;
            height: 40px;
            cursor: pointer;

            img {
                width: 9px;
                margin: auto;
                transform: scaleY(-1);
                transition: transform 0.3s;
            }
        }

        &__list {
            position: absolute;
            top: 100%;
            right: 0;
            display: flex;
            flex-direction: column;
            width: 320px;
            padding: 0 35px 10px 20px;
            background-color: #323232;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s;
            z-index: 100;

            a {
                margin-left: auto;
                font-size: 14px;
                line-height: 20px;
                color: rgba(255, 255, 255, 0.4);
                transition: color 0.3s;

                &:hover {
                    color: #ffffff;
                }
            }
        }

        &.open,
        &:hover {
            .topbar-select__icon {
                img {
                    transform: none;
                }
            }

            .topbar-select__list {
                opacity: 1;
                pointer-events: initial;
            }
        }

        &--short {
            .topbar {
                &-select {
                    &__list {
                        width: 240px;
                    }
                }

                &-moblist {
                    max-width: 240px;
                }
            }
        }
    }

    &-moblist {
        position: absolute;
        top: 0;
        right: -20px;
        display: none;
        flex-direction: column;
        width: 100vw;
        max-width: 320px;
        padding: 10px 35px 10px 15px;
        background-color: #323232;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;
        z-index: 100;

        &__icon {
            position: absolute;
            top: 5px;
            right: 0;
            display: flex;
            flex-direction: column;
            width: 35px;
            height: 30px;
            cursor: pointer;

            img {
                width: 9px;
                margin: auto;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            margin: auto 0;

            a {
                margin-left: auto;
                margin-bottom: 5px;
                font-size: 14px;
                line-height: 20px;
                color: #ffffff;

                &:last-child {
                    margin-bottom: 0;
                }

                span {
                    margin-left: 10px;
                }
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-lg) {
        gap: 10px;

        &__nav {
            ul {
                gap: 10px 20px;
            }
        }

        &__item {
            img {
                display: none;
            }
        }

        &-select {
            &__socials {
                display: none;
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-md) {
        gap: 20px;
        height: 55px;
        z-index: 150;
        transition: height 0.3s;

        &.min {
            height: 40px;
        }

        &__burger {
            display: flex;
        }

        &__nav {
            display: none;
        }

        &__item {
            img {
                display: block;
            }
        }

        &-select {
            display: flex;

            &__mobicon {
                display: flex;
                flex-direction: column;
                width: 24px;
                margin: auto 0;
                transition: opacity 0.3s;
                cursor: pointer;

                &:hover {
                    opacity: 0.6;
                }

                img {
                    width: 20px;
                    margin: auto;
                }
            }

            &__main {
                order: -1;

                & > * {
                    display: none;
                }

                .topbar-select__socials {
                    display: flex;
                    margin-right: 15px;
                }
            }

            &__icon {
                display: none;
            }

            &__list {
                display: none;
            }
        }

        &-moblist {
            display: flex;

            &.open {
                opacity: 1;
                pointer-events: initial;
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-sm) {
        &__item {
            display: none;
        }
    }
}
