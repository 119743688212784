.news-card {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;

    &__image {
        position: relative;
        width: 100%;
        height: 285px;

        @include is-screen-max($breakpoint-md) {
            height: 245px;
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #363636;
            opacity: 0.5;
            transition: opacity 0.3s;
        }

        img {
            width: 100%;
            height: 100%;
            max-width: initial;
            max-height: initial;
            object-fit: cover;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 20px;
        padding: 20px 20px 42px;
        background-color: #f6f6f6;

        @include is-screen-max($breakpoint-md) {
            gap: 24px;
            padding: 30px 20px;
        }
    }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 10px;

        @include is-screen-max($breakpoint-md) {
            align-items: flex-start;
        }
    }

    &__date {
        display: flex;
        align-items: flex-end;
        gap: 8px;
        flex-shrink: 0;

        img {
            width: 24px;
        }

        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            color: #448a33;
        }
    }

    &__author {
        font-size: 16px;
        line-height: 100%;
        color: #363636;
        text-align: right;
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include is-screen-max($breakpoint-md) {
            gap: 20px;
        }
    }

    &__title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        color: #363636;
        overflow: hidden;
        transition: color 0.3s;
    }

    &__description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #363636;
        overflow: hidden;
    }

    &:hover {
        .news-card {
            &__image {
                &::before {
                    opacity: 0.2;
                }
            }

            &__title {
                color: #448a33;
            }
        }
    }
}
