.page-news {
    margin-bottom: 110px;

    @include is-screen-max($breakpoint-md) {
        margin-bottom: 50px;
    }

    &__titlearea {
        width: 100%;
        margin-top: 50px;
        margin-bottom: 40px;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        @include is-screen-max($breakpoint-md) {
            margin-top: 20px;
            margin-bottom: 35px;
            padding-bottom: 20px;
        }

        .breadcrumbs {
            margin-bottom: 20px;
        }
    }

    &__title {
        font-weight: 300;
        font-size: 36px;
        line-height: 100%;
        color: #003201;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-md) {
            font-size: 24px;
        }
    }
}
