/**********************************************************************/
/**********************************************************************/
.sidebar {
    display: flex;
    flex-direction: column;
    width: 225px;
    margin-top: -50px;

    &-nav {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;

        li {
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }

        &__link {
            padding: 17px 0 13px;
            font-size: 14px;
            line-height: 20px;
            color: #787878;
            text-transform: uppercase;
            transition: opacity 0.3s;
            cursor: pointer;

            &:hover {
                opacity: 0.6;
            }

            &.active {
                color: #424242;
            }
        }
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-lg) {
        display: none;
    }

    /******************************************************************/
    /******************************************************************/
    @include is-screen-max($breakpoint-md) {
        &-nav {
            flex-direction: row;
            flex-wrap: wrap;

            li {
                width: auto;
                margin: 0 20px 0 0;
                border-bottom: none;

                &:last-child {
                    margin-right: 0;
                }
            }

            &__link {
                padding: 5px 0;
            }
        }
    }
}
